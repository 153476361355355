import { Card, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Icon } from 'components/Icon';
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react';
import { StaticDocument } from 'models/referentiels/Document.models';
import { useTheme } from '@mui/material/styles';
import { fetchDocumentUrl } from 'api/referentiels/document.api';
import { useAccessToken } from 'utils/api/api';

interface DocumentCardProps {
  document: StaticDocument;
}

export const DocumentCard: React.FC<DocumentCardProps> = ({ document }) => {
  const theme = useTheme();
  const accessToken = useAccessToken();

  const handleDownload = (document: StaticDocument) => async () => {
    if (accessToken) {
      try {
        const url = await fetchDocumentUrl(document.id, accessToken);
        window.open(url);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <Grid key={document.id} item xs={12} sm={4} md={3}>
      <Card
        sx={{
          borderRadius: 4,
          py: 0,
          height: '100%',
          cursor: 'pointer',
          ':hover': {
            border: `1px solid ${theme.palette.primary.main}`,
          },
          display: 'flex',
          flexDirection: 'row',
          border: `1px solid ${theme.palette.divider}`,
        }}
        onClick={handleDownload(document)}
      >
        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          sx={{ p: 2, width: '100%' }}
        >
          <Typography
            gutterBottom
            variant="body1"
            sx={{
              mb: 0,
            }}
          >
            {document.type.label}
          </Typography>
          <Icon fill={theme.palette.background.default}>
            <DownloadIcon />
          </Icon>
        </Stack>
      </Card>
    </Grid>
  );
};
