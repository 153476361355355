import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { appName } from 'App';
import CardError from 'components/Card/CardError';
import { useAppContext } from 'components/Context/AppContext';
import { ChevronBottomIcon } from 'components/Icon/ChevronBottomIcon';
import { PromotionCard } from 'components/PromotionCard/PromotionCard';

import { CardFormulaList } from 'content/formula/CardFormulaList';
import CardSubscription from 'content/subscription/CardSubscription';
import { OfferDetail } from 'content/offer/OfferDetail';
import { TarificationDisclamers } from 'content/offer/TarificationDisclamers';

import { RestError } from 'errors/RestError';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { projectStepMutations } from 'api/oav/ProjectStepQuery.api';
import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';
import { ProjectStep } from 'models/oav/ProjectStep.models';
import { Subscription } from 'models/oav/Subscription.models';
import { firstMonthPromotion } from 'models/oav/Promotion.model';
import {
  navigateToNextStep,
  navigateToPreviousStep,
} from 'utils/project/project';
import useOnScreen from 'utils/hooks/useOnScreen';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { tarificationToMap } from 'utils/tarification/tarification';
import CardOfferDetail from 'content/offer/CardOfferDetail';

const CURRENT_STEP = ProjectStep.PROPOSAL;

const ProjectProposalPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const theme = useTheme();

  if (!id) return <></>;

  const { addSnackbar } = useAppContext();
  const refStickyAnchor = useRef<HTMLDivElement>(null);
  const isStickyAnchorVisible = useOnScreen(refStickyAnchor, { threshold: 1 });

  const projectQuery = useQuery(projectQueries.getById(id));
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(id));

  const mutation = projectStepMutations.updateProjectStepProposal(id, {
    onSuccess: () => {
      navigateToNextStep(CURRENT_STEP, id, navigate);
    },
    onError: () => {
      addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    },
  });

  const getSubscriptionFromOfferCode = (code: string) => {
    const subscriptions = projectQuery.data?.subscriptions;
    if (!subscriptions) return;

    const sub = subscriptions.find(_ => _.offerCode === code);
    if (sub) return sub;
    else if (subscriptions.length > 0) return subscriptions[0];
  };

  const [subscriptionCurrent, setSubscriptionCurrent] = useState<
    Subscription | undefined
  >(getSubscriptionFromOfferCode(searchParams.get('offre') as string));

  useEffect(() => {
    setSubscriptionCurrent(
      getSubscriptionFromOfferCode(searchParams.get('offre') as string),
    );
  }, [projectQuery?.data]);

  useEffect(() => {
    if (!subscriptionCurrent?.offerCode) return;
    setSearchParams(prev => ({
      ...prev,
      offre: subscriptionCurrent.offerCode,
    }));
  }, [subscriptionCurrent?.offerCode]);

  const tarificationsQuery = useQuery({
    ...subscriptionQueries.getByIdTarification(
      id,
      subscriptionCurrent?.id || '',
    ),
    enabled: !!subscriptionCurrent?.id,
  });

  const tarificationMap = useMemo(
    () => tarificationToMap(tarificationsQuery.data),
    [tarificationsQuery.data],
  );

  const offer = useMemo(() => {
    if (subscriptionCurrent && offersMapQuery.data) {
      return offersMapQuery.data[subscriptionCurrent.offerCode];
    }
  }, [subscriptionCurrent, offersMapQuery.data]);

  const hasPromotion = (code: string) => code === '512' || code === '312';

  if (projectQuery.isError || offersMapQuery.isError) {
    return (
      <Grid item xs={12} sx={{ height: 200 }}>
        <CardError
          status={
            projectQuery.error instanceof RestError
              ? projectQuery.error.status
              : undefined
          }
        />
      </Grid>
    );
  }

  if (
    !projectQuery.data ||
    !offersMapQuery.data ||
    !subscriptionCurrent ||
    !offer
  ) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  const handleValidate = () => {
    mutation.mutate({});
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          useFlexGap
          gap={1}
        >
          <Typography variant="h3" flexGrow={1}>
            Proposition
          </Typography>
          {projectQuery.data.subscriptions?.length &&
            projectQuery.data.subscriptions?.length > 1 && (
              <ToggleButtonGroup
                exclusive
                color="primary"
                size="small"
                value={subscriptionCurrent?.offerCode}
                onChange={(e, v) => {
                  setSubscriptionCurrent(getSubscriptionFromOfferCode(v));
                }}
              >
                {projectQuery.data.subscriptions.map(_ => (
                  <ToggleButton value={_.offerCode} key={_.offerCode}>
                    {offersMapQuery.data[_.offerCode].label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
        </Stack>
      </Grid>

      <Grid
        item
        xs={12}>
        <CardSubscription
          project={projectQuery.data}
          subscription={subscriptionCurrent}
          offer={offer}
          showForm
          onUpdate={_ => setSubscriptionCurrent(_)}
        >
          <Stack gap={2}>
            <TarificationDisclamers
              disclamers={tarificationsQuery.data?.disclaimers}
            />
            {offer && hasPromotion(offer.code) && (
              <PromotionCard promotion={firstMonthPromotion} />
            )}
            <CardFormulaList
              offer={offer}
              tarification={tarificationsQuery.data}
              recommendedFormulaCode={
                subscriptionCurrent.recommendedFormulaCode
              }
            />
          </Stack>
        </CardSubscription>
      </Grid>

      {offer?.reinforcements && (
        <>
          <Grid item xs={12}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={1}>
              <Typography variant="h3">
                Renforts
              </Typography>
              <Typography variant="h3">
                <Chip label={offer.reinforcements.length} />
              </Typography>
            </Stack>
          </Grid>
          {offer.reinforcements.map(_ => (
            <Grid
              key={_.code}
              item
              xs={12}>
              <CardOfferDetail
                offer={_}
                baseFormulas={offer.formulas}
                tarification={tarificationMap[_.code]} />
            </Grid>
          ))}
        </>
      )}

      {offer?.options && (
        <>
          <Grid item xs={12}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={1}>
              <Typography variant="h3">
                Options
              </Typography>
              <Typography variant="h3">
                <Chip label={offer.options.length} />
              </Typography>
            </Stack>
          </Grid>
          {offer.options.map(_ => (
            <Grid
              key={_.code}
              item
              xs={12}>
              <CardOfferDetail
                offer={_}
                tarification={tarificationMap[_.code]} />
            </Grid>
          ))}
        </>
      )}

      <Grid
        item
        xs={12}
        sx={{
          zIndex: 1001,
          position: {
            md: 'sticky',
            xs: 'static',
          },
          bottom: 16,
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid
            item
            sm="auto"
            xs={12}
            order={{
              sm: 0,
              xs: 1,
            }}
          >
            <Button
              fullWidth
              color="default"
              disabled={mutation.isPending}
              onClick={() => {
                navigateToPreviousStep(CURRENT_STEP, id, navigate);
              }}
              sx={{ px: 4 }}
            >
              Retour
            </Button>
          </Grid>
          <Grid
            item
            sm="auto"
            xs={12}
            order={{
              sm: 0,
              xs: 1,
            }}
          >
            <Button
              fullWidth
              disabled={mutation.isPending}
              sx={{ px: 4 }}
              onClick={handleValidate}
            >
              {mutation.isPending ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                'Valider'
              )}
            </Button>
          </Grid>

          {!isStickyAnchorVisible && (
            <Grid item xs="auto">
              <Tooltip title="Remonter" placement="top">
                <Button
                  color="default"
                  onClick={() =>
                    refStickyAnchor.current?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'nearest',
                    })
                  }
                  sx={{
                    minWidth: 0,
                    p: 1,
                  }}
                >
                  <ChevronBottomIcon
                    size="medium"
                    style={{ transform: 'rotate(180deg)' }}
                  />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectProposalPage;
