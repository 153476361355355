import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';

import PriceNumber from 'content/formula/PriceNumber';
import CardSolutionItem from 'content/solution/CardSolutionItem';
import ChipPeriod from 'content/solution/ChipPeriod';

import {
  Solution,
  SolutionItem,
  TarificationPeriod,
} from 'models/oav/Solution.models';
import { Offer } from 'models/referentiels/Offer.model';

export const getSolutionItems = (
  items?: SolutionItem[],
  offers?: Offer[],
): SolutionItem[] => {
  let result = (offers || []).map(_ => {
    const matchingItem = items?.find(item => item.offer.code === _.code);

    return {
      offer: matchingItem?.offer || {
        code: _.code,
        label: _.label,
        description: _.description,
      },
      formula: matchingItem?.formula,
      pricing: matchingItem?.pricing,
    };
  });

  result = result.concat(
    (items || [])
      .filter(_ => !result.some(element => element.offer.code === _.offer.code))
      .map(_ => {
        return {
          offer: _.offer,
          formula: _.formula,
          pricing: _?.pricing,
        };
      }),
  );

  return result;
};

export type CardSolutionProps = {
  solution: Solution;
  offer?: Offer;
  selected?: boolean;
  disabled?: boolean;
  period?: TarificationPeriod;
  onPeriodChange?: (period: TarificationPeriod) => void;
  onSelect?: () => void;
  onDelete?: () => void;
  onClickItem?: (item: SolutionItem) => void;
  onClick?: () => void;
};

const CardSolution = (props: CardSolutionProps) => {
  const theme = useTheme();

  const enabled = !props.disabled && props.onClick;

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 0,
        outline: props.selected ? 2 : 0,
        outlineColor: props.selected ? theme.palette.primary.main : 'none',
      }}
    >
      <Box
        onClick={() => !props.disabled && props.onClick?.()}
        sx={{
          p: 0.5,
          borderBottom: 1,
          borderColor: alpha('#000000', 0.15),
          background: alpha('#000000', 0.1),
          cursor: enabled ? 'pointer' : 'default',
          ':hover': enabled
            ? {
                background: alpha('#000000', 0.15),
                color: theme.palette.primary.main,
              }
            : {},
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            fontWeight="bold"
            sx={{
              px: 1,
              flexGrow: 1,
            }}
          >
            Solution {props.solution.order && ` n°${props.solution.order}`}
          </Typography>
          <Stack direction="row">
            {props.onDelete && (
              <Tooltip
                placement="top"
                title={'Supprimer'}
                componentsProps={{
                  tooltip: {
                    sx: {
                      color: theme.palette.error.main,
                    },
                  },
                }}
              >
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    props.onDelete?.();
                  }}
                  sx={{
                    ':hover': {
                      color: theme.palette.error.main,
                      background: alpha(theme.palette.error.main, 0.1),
                    },
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {props.onSelect && (
              <Checkbox
                size="small"
                checked={props.selected === true}
                disabled={props.disabled}
              />
            )}
          </Stack>
        </Box>
      </Box>

      <Box
        sx={{
          px: 1.5,
          pt: 1,
          textAlign: 'right',
        }}
      >
        <ChipPeriod period={props.period} onChange={props.onPeriodChange} />
      </Box>

      <Stack
        spacing={1}
        sx={{
          py: 1.5,
          flexGrow: 1,
        }}
      >
        {/* BASE */}
        <CardSolutionItem
          offer={props.solution.base.offer}
          formula={props.solution.base.formula}
          pricing={props.solution.base.pricing}
          period={props.period}
          onClick={
            props.onClickItem &&
            (e => {
              e.stopPropagation();
              props.onClickItem?.(props.solution.base);
            })
          }
        />

        {/* REINFORCEMENTS */}
        {getSolutionItems(
          props.solution.reinforcements,
          props.offer?.reinforcements,
        ).map(_ => (
          <CardSolutionItem
            key={_.offer.code}
            offer={_.offer}
            formula={_.formula}
            pricing={_.pricing}
            period={props.period}
            onClick={
              props.onClickItem &&
              (e => {
                e.stopPropagation();
                props.onClickItem?.(_);
              })
            }
          />
        ))}

        {/* OPTIONS */}
        {getSolutionItems(props.solution.options, props.offer?.options).map(
          _ => (
            <CardSolutionItem
              key={_.offer.code}
              offer={_.offer}
              formula={_.formula}
              pricing={_.pricing}
              period={props.period}
              onClick={
                props.onClickItem &&
                (e => {
                  e.stopPropagation();
                  props.onClickItem?.(_);
                })
              }
            />
          ),
        )}
      </Stack>

      <Box
        sx={{
          containerType: 'inline-size',
          p: 1.5,
          borderTop: 1,
          borderColor: alpha('#000000', 0.15),
          background: alpha('#000000', 0.1),
        }}
      >
        <Stack direction="row" flexWrap="wrap">
          <Typography
            fontWeight="bold"
            sx={{
              '@container (max-width: 200px)': {
                textAlign: 'right',
                width: '100%',
              },
            }}
          >
            Total
          </Typography>
          <Stack
            direction={
              props.period === TarificationPeriod.ANNUALLY
                ? 'column-reverse'
                : 'column'
            }
            sx={{
              containerType: 'inline-size',
              width: '100%',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap="wrap"
              spacing={1}
            >
              <Typography variant="caption">par mois</Typography>
              <Typography
                sx={{
                  flexGrow: 1,
                  textAlign: 'right',
                  '@container (max-width: 200px)': {
                    width: '100%',
                  },
                }}
              >
                <PriceNumber price={props.solution.pricing?.perMonth} />
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap="wrap"
              spacing={1}
            >
              <Typography variant="caption">par an</Typography>
              <Typography
                sx={{
                  flexGrow: 1,
                  textAlign: 'right',
                  '@container (max-width: 200px)': {
                    width: '100%',
                  },
                }}
              >
                <PriceNumber price={props.solution.pricing?.perYear} />
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
};

export default CardSolution;
