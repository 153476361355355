import { useRef, useState } from 'react';
import { queryClient } from 'config';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import dayjs from 'dayjs';

import { alpha, useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useAppContext } from 'components/Context/AppContext';
import DatePicker from 'components/DatePicker/DatePicker';

import {
  Subscription,
  SubscriptionRequest,
  SubscriptionRequestErrors,
} from 'models/oav/Subscription.models';
import { ApiResponse } from 'models/api.model';
import { updateSubscription } from 'api/oav/Subscription.api';

import {
  dateStartDayMax,
  dateStartDayMin,
  getYupFieldDateStart,
} from 'utils/validation/yupSubscription';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { useAccessToken } from 'utils/api/api';
import { formatDate } from 'utils/date';
import { RestError } from 'errors/RestError';

export interface SubscriptionDateDialogProps extends DialogProps {
  projectId?: string;
  subscription?: Subscription;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const SubscriptionDateDialog = (props: SubscriptionDateDialogProps) => {
  const theme = useTheme();
  const projectId = props.projectId;
  const subscription = props.subscription;

  if (!projectId || !subscription) return <></>;

  const validationSchema = Yup.object().shape({
    dateStart: getYupFieldDateStart(),
  });

  const [loading, setLoading] = useState(false);

  const formikRef = useRef<FormikProps<any>>(null);
  const [formikErrors, hasFormikErrors] = useState<boolean>(false);

  const { addSnackbar } = useAppContext();
  const onBadRequest = (errors: SubscriptionRequestErrors) => {
    formikRef.current?.setFieldError('dateStart', errors.dateStart);
  };

  const accessToken = useAccessToken();
  const mutationSubscription = useMutation({
    mutationFn: (payload: SubscriptionRequest) => {
      setLoading(true);
      return updateSubscription(
        accessToken,
        projectId,
        subscription!.id,
        payload,
      );
    },
    onSuccess: () => {
      setLoading(false);

      queryClient.resetQueries({
        queryKey: ['projects', projectId, 'proposal'],
        exact: true
      });

      queryClient.invalidateQueries({
        queryKey: ['projects', projectId],
      });

      if (props.onSuccess) props.onSuccess();
    },
    onError: (error: Error) => {
      setLoading(false);
      if (error instanceof RestError && error.status === 400) {
        const e = (error.response as ApiResponse<SubscriptionRequestErrors>)
          .details;
        if (e) onBadRequest(e);
      } else addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    },
  });
  return (
    <Dialog {...props}>
      <DialogTitle>Date d'effet expirée</DialogTitle>

      <DialogContent>
        <Formik
          innerRef={formikRef}
          initialValues={{
            dateStart: dayjs()
              .startOf('day')
              .add(dateStartDayMin, 'day')
              .toDate(),
          }}
          validationSchema={validationSchema}
          onSubmit={values =>
            mutationSubscription.mutate({
              dateStart: values.dateStart,
              offerCode: subscription.offerCode,
            })
          }
        >
          {({ errors, touched, setFieldTouched, setFieldValue }) => {
            hasFormikErrors(Boolean(errors.dateStart));
            return (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Alert
                    icon={false}
                    variant="outlined"
                    severity="info"
                    sx={{
                      border: 2,
                      borderColor: theme.palette.info.main,
                      backgroundColor: alpha(theme.palette.info.main, 0.1),
                    }}
                  >
                    <Typography>
                      La date d'effet de votre proposition (
                      {formatDate(subscription.dateStart)}) n'est plus valide.
                    </Typography>
                    <Typography variant="body2">
                      Veuillez sélectionner une nouvelle date d'effet postérieure à la date du jour.
                    </Typography>
                  </Alert>
                </Grid>

                <Grid item xs={12}>
                  <DatePicker
                    label="Date d'effet"
                    format="DD/MM/YYYY"
                    minDate={dayjs().startOf('day').add(dateStartDayMin, 'day')}
                    maxDate={dayjs().startOf('day').add(dateStartDayMax, 'day')}
                    defaultValue={dayjs()
                      .startOf('day')
                      .add(dateStartDayMin, 'day')}
                    onChange={v => {
                      setFieldValue('dateStart', v);
                      setFieldTouched('dateStart');
                    }}
                    slotProps={{
                      textField: {
                        sx: {
                          width: '100%',
                        },
                        required: true,
                        onBlur: () => setFieldTouched('dateStart'),
                        error: touched.dateStart && Boolean(errors.dateStart),
                        helperText: touched.dateStart &&
                          Boolean(errors.dateStart) && <>{errors.dateStart}</>,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
        }}
      >
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              variant="text"
              color="default"
              disabled={loading}
              onClick={() => props.onCancel != null && props.onCancel()}
              sx={{ px: 4 }}
            >
              Retour
            </Button>
          </Grid>

          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              onClick={() => formikRef.current?.submitForm()}
              disabled={loading || formikErrors}
              sx={{ px: 4 }}
            >
              {loading ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                'Valider'
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
