import { RestError } from '../../errors/RestError';
import { captureError } from '../../sentry/SentryConfig';

const httpRequest = async (
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  accessToken: string,
  headers: Record<string, string>,
  body?: any,
) => {
  const options: RequestInit = {
    method,
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(url, options);
  return await handleResponse(
    response,
    method,
    url,
    body ? JSON.stringify(body) : undefined,
  );
};

export const getRequest = (
  url: string,
  accessToken: string,
  headers = { Accept: 'application/json' },
) => httpRequest('GET', url, accessToken, headers);

export const postRequest = (
  url: string,
  body: any,
  accessToken: string,
  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
) => httpRequest('POST', url, accessToken, headers, body);

export const putRequest = (
  url: string,
  body: any,
  accessToken: string,
  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
) => httpRequest('PUT', url, accessToken, headers, body);

export const deleteRequest = (
  url: string,
  accessToken: string,
  headers = { Accept: 'application/json' },
) => httpRequest('DELETE', url, accessToken, headers);

const handleResponse = async (
  response: Response,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  url: string,
  strBody: string | undefined,
): Promise<any> => {
  if (response.status / 100 >= 4) {
    const error = await response.json();
    captureError(`${method} - ${url}`, response.status, { response: error });
    throw new RestError(response.status, url, method, strBody, error);
  }

  if (response.status === 204) {
    return;
  }

  return await response.json();
};
