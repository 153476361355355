import AppBar from 'components/AppBar';
import React, { ChangeEvent, useState } from 'react';
import {
  Chip,
  Grid,
  InputAdornment,
  TextField,
  Stack,
  Typography,
  Link as LinkMUI,
  Box,
  Button,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useRisks, useSegments } from 'queries';
import {
  ContractSearchRequest,
  ContractStatus,
  enumContractStatus,
} from 'models/oav/Contract.model';
import { useFilter } from 'pages/offer/hooks/filters';
import { Risk } from 'models/referentiels/Risk.model';
import { Segment } from 'models/referentiels/Segment';
import SelectFilter from 'components/Select/SelectFilter';
import { useTheme } from '@mui/material/styles';
import { SearchIcon } from 'components/Icon/SearchIcon';
import { useDebounce } from 'use-debounce';
import { Offer, OfferType } from 'models/referentiels/Offer.model';
import { useQuery } from '@tanstack/react-query';
import { offerQueries } from 'api/referentiels/OfferQuery.api';
import FilterChip from 'components/Chip/FilterChip';
import { ContractTab, ContractTable } from './ContractTable';

const WalletPage: React.FC = () => {
  document.title = 'Portefeuille';

  const theme = useTheme();

  // load segments
  const { data: segmentOptions } = useSegments();
  const [segments, handleChangeSegments, resetSegments, removeSegment] =
    useFilter<Segment>();

  // load risks
  const { data: riskOptions } = useRisks();
  const [risks, handleChangeRisks, resetRisks, removeRisk] = useFilter<Risk>();

  // load offers
  const offerOptions = useQuery(
    offerQueries.getAll({
      inTypes: [OfferType.BASE, OfferType.OPTION],
    }),
  );
  //const { data: offerOptions } = useOffers();
  const [offers, handleChangeOffers, resetOffers, removeOffer] =
    useFilter<Offer>();

  // load statuses
  const contractStatuses = enumContractStatus;
  const [statuses, handleChangeStatus, resetStatuses, removeStatus] =
    useFilter<ContractStatus>();

  // search text
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [totalContracts, setTotal] = useState(0);
  const setTotalContract = (total: number) => setTotal(total);

  // init filters
  const filters: ContractSearchRequest = {
    risks: risks.map(r => r.label),
    segments: segments.map(s => s.label),
    offers: offers.map(o => o.label),
    statuses: statuses.map(s => s.key),
    search: debouncedSearchText,
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const resetFilters = () => {
    resetRisks();
    resetSegments();
    resetStatuses();
    resetOffers();
  };

  const displayResetFilter =
    risks.length > 0 ||
    segments.length > 0 ||
    statuses.length > 0 ||
    offers.length > 0;

  const contractTabs: ContractTab[] = [
    { key: 'effectiveDate', sort: true },
    { key: 'riskLabel' },
    { key: 'segmentLabel' },
    { key: 'offerLabel' },
    { key: 'code' },
    { key: 'subscriberCode' },
    { key: 'subscriber', sort: true },
    { key: 'status' },
    { key: 'cancelDate', sort: true },
  ];

  return (
    <>
      <AppBar>
        <Box
          sx={{
            width: '100%',
            pb: 3,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap="wrap"
            useFlexGap
            spacing={1}
            sx={{
              width: '100%',
            }}
          >
            <Typography variant="h2">Portefeuille</Typography>
            <TextField
              fullWidth
              variant="outlined"
              value={searchText}
              placeholder="Recherche"
              onChange={handleSearchChange}
              sx={{
                maxWidth: '500px',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color={theme.palette.text.secondary} />
                  </InputAdornment>
                ),
              }}
            />
            <Link to="commissions">
              <Button variant="gradient" color="secondary">
                Commissions
              </Button>
            </Link>
          </Stack>
        </Box>
      </AppBar>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" flexWrap="wrap" useFlexGap spacing={2}>
              <SelectFilter
                disabled={false}
                onChange={handleChangeRisks}
                multiple
                showCount
                options={riskOptions}
                value={risks}
                getOptionKey={option => option.code}
                renderElement={option => option.label}
              >
                Risque
              </SelectFilter>
              <SelectFilter
                disabled={false}
                onChange={handleChangeSegments}
                multiple
                showCount
                options={segmentOptions}
                value={segments}
                getOptionKey={option => option.code}
                renderElement={option => option.label}
              >
                Segment
              </SelectFilter>
              <SelectFilter
                disabled={false}
                onChange={handleChangeOffers}
                multiple
                showCount
                options={offerOptions?.data}
                value={offers}
                getOptionKey={option => option.code}
                renderElement={option => option.label}
              >
                Offre
              </SelectFilter>
              <SelectFilter
                disabled={false}
                onChange={handleChangeStatus}
                multiple
                showCount
                options={contractStatuses}
                value={statuses}
                getOptionKey={option => option.code}
                renderElement={option => option.label}
                isOptionEqualToValue={(o, v) => o.key === v.key}
              >
                Etat
              </SelectFilter>
            </Stack>

            <Typography
              variant="body1"
              sx={{
                display: {
                  sm: 'flex',
                  xs: 'none',
                },
              }}
            >
              Total des contrats :&nbsp;
              <Chip
                label={totalContracts}
                color="default"
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  fontWeight: 'bold',
                }}
                variant="filled"
                size="small"
              />
            </Typography>
          </Stack>
        </Grid>

        {displayResetFilter && (
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              useFlexGap
              spacing={2}
            >
              {risks.map(item => (
                <FilterChip
                  key={item.code}
                  item={item}
                  theme={theme}
                  onDelete={removeRisk(item)}
                />
              ))}
              {segments.map(item => (
                <FilterChip
                  key={item.code}
                  item={item}
                  theme={theme}
                  onDelete={removeSegment(item)}
                />
              ))}
              {offers.map(item => (
                <FilterChip
                  key={item.code}
                  item={item}
                  theme={theme}
                  onDelete={removeOffer(item)}
                />
              ))}
              {statuses.map(item => (
                <FilterChip
                  key={item.key}
                  item={item}
                  theme={theme}
                  onDelete={removeStatus(item)}
                />
              ))}
              {displayResetFilter && (
                <LinkMUI onClick={resetFilters}>Tout effacer</LinkMUI>
              )}
            </Stack>
          </Grid>
        )}
      </Grid>

      <ContractTable
        searchRequest={filters}
        tabs={contractTabs}
        onContractFetched={setTotalContract}
        pageSize={10}
        emptyMessage={"Vous n'avez pas de contrat en portefeuille."}
        displayPagination
      />
    </>
  );
};

export default WalletPage;
