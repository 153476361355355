import { Solution, SolutionRequest } from 'models/oav/Solution.models';
import { MutationGenerator, useAccessToken } from 'utils/api/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createSolution,
  deleteSolution,
  updateSolution,
} from 'api/oav/Solution.api';

const useCreateSolution: MutationGenerator<
  Solution,
  { body: SolutionRequest; projectId: string }
> = ({ onSuccess, onError, ...mutationOptions }) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    ...mutationOptions,
    mutationFn: ({ body }) => createSolution(accessToken, body),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [
          'projects',
          variables.projectId,
          'subscription',
          variables.body.subscriptionId,
          'solutions',
        ],
      });

      // Create a solution might change the project step.
      queryClient.invalidateQueries({
        queryKey: ['projects', variables.projectId],
        exact: true,
      });
      onSuccess?.(data, variables, context);
    },
    onError: (error: Error, variables, context) => {
      onError?.(error, variables, context);
    },
  });
};

const useUpdateSolution: MutationGenerator<
  Solution,
  { body: SolutionRequest; id: string; projectId: string }
> = ({ onSuccess, onError, ...mutationOptions }) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    ...mutationOptions,
    mutationFn: ({ body, id }) => updateSolution(accessToken, id, body),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [
          'projects',
          variables.projectId,
          'subscription',
          variables.body.subscriptionId,
          'solutions',
        ],
      });

      // Update a solution might change the project step.
      queryClient.invalidateQueries({
        queryKey: ['projects', variables.projectId],
        exact: true,
      });
      onSuccess?.(data, variables, context);
    },
    onError: (error: Error, variables, context) => {
      onError?.(error, variables, context);
    },
  });
};

const useDeleteSolution: MutationGenerator<
  void,
  { id: string; projectId: string; subscriptionId: string }
> = ({ onSuccess, onError, ...mutationOptions }) => {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    ...mutationOptions,
    mutationFn: ({ id }) => deleteSolution(accessToken, id),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [
          'projects',
          variables.projectId,
          'subscription',
          variables.subscriptionId,
          'solutions',
        ],
      });

      // Delete a solution might change the project step.
      queryClient.invalidateQueries({
        queryKey: ['projects', variables.projectId],
        exact: true,
      });
      onSuccess?.(data, variables, context);
    },
    onError: (error: Error, variables, context) => {
      onError?.(error, variables, context);
    },
  });
};

export const solutionMutations = {
  useCreateSolution,
  useUpdateSolution,
  useDeleteSolution,
};
