import { useMemo, useState } from 'react';

import { keyframes, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import { useAppContext } from 'components/Context/AppContext';
import {
  CommercialProposition,
  SignatureMode,
  SignatureStatus,
} from 'models/oav/CommercialProposition.models';
import { Project } from 'models/oav/Project.models';
import {
  ProjectStatus,
  projectStatusData,
} from 'models/oav/ProjectStatus.models';
import { projectMutations } from 'api/oav/ProjectQuery.api';

import { formatDate, formatTime } from 'utils/date';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';

type TimelineEventProps = {
  status: ProjectStatus;
  timestamp?: Date;
  isSignedManual?: boolean;
  children?: JSX.Element;
};

const TimelineEvent = (props: TimelineEventProps) => {
  const status = projectStatusData[props.status];

  return (
    <Stack spacing={1}>
      <Box>
        <Typography fontWeight={600} sx={{ pb: 1 }}>
          {status.label}
          {props.isSignedManual !== undefined && (
            <>
              {props.isSignedManual
                ? '  -  Manuellement ✒️'
                : '  -  Électroniquement 🌐'}
            </>
          )}
        </Typography>
        {props.timestamp && (
          <Typography variant="caption">
            le {formatDate(props.timestamp)} à {formatTime(props.timestamp)}
          </Typography>
        )}
        {status.description && <Typography>{status.description}</Typography>}
      </Box>

      {props.children && (
        <>
          <Divider />
          <Box>{props.children}</Box>
        </>
      )}
    </Stack>
  );
};

export type ProjectTimelineProps = {
  project: Project;
  proposal: CommercialProposition;
  nbEvents?: number;
};

const ProjectTimeline = (props: ProjectTimelineProps) => {
  const theme = useTheme();
  const { addSnackbar } = useAppContext();

  const [nbEvents, setNbEvents] = useState<number | undefined>(
    props.nbEvents || 1,
  );

  const isSignedManual = props.proposal.signatureMode == SignatureMode.MANUAL;

  const events = useMemo<ProjectStatus[]>(() => {
    if (!props.project.status) return [];

    const e = [props.project.status];

    if (props.project.status === ProjectStatus.SIGNATURE_DONE) {
      e.unshift(ProjectStatus.MANAGEMENT_IN_PROGRESS);
      if (!isSignedManual) {
        e.push(ProjectStatus.SIGNATURE_IN_PROGRESS);
      }
    }

    if (
      props.project.status === ProjectStatus.MANAGEMENT_FAILED ||
      props.project.status === ProjectStatus.MANAGEMENT_DONE
    ) {
      e.push(ProjectStatus.SIGNATURE_DONE);
      if (!isSignedManual) {
        e.push(ProjectStatus.SIGNATURE_IN_PROGRESS);
      }
    }

    return e;
  }, [props.project, props.proposal]);

  const signatureCancelMutation =
    projectMutations.useCancelSignatureLinkMutation({
      onError: () => {
        addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
      },
    });
  const pingAnimation = keyframes`
      0% {
        transform: scale(1);
        opacity: 1;
      }
      100% {
        transform: scale(2);
        opacity: 0;
      }
    `;

  return (
    <Box>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            p: 0,
          },
          p: 0,
          pl: 1.5,
          m: 0,
        }}
      >
        {events.slice(0, nbEvents || events.length).map((_, idx) => {
          const status = projectStatusData[_];
          const isLast =
            idx === events.slice(0, nbEvents || events.length).length - 1;
          const color = idx === 0 ? status.color : theme.palette.success.main;
          const isPinging = _ === ProjectStatus.MANAGEMENT_IN_PROGRESS;

          const isSignatureExpired =
            props.proposal.signatureExpireAt &&
            props.proposal.signatureExpireAt.getTime() - Date.now() <= 0;

          return (
            <TimelineItem key={_}>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    position: 'relative',
                    background: color,
                  }}
                >
                  {isPinging && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '-3.5px',
                        left: '-3.5px',
                        width: '14px',
                        height: '14px',
                        borderRadius: '50%',
                        backgroundColor: color,
                        animation: `${pingAnimation} 1.5s infinite`,
                      }}
                    />
                  )}
                </TimelineDot>
                {!isLast && (
                  <TimelineConnector
                    sx={{
                      background: color,
                    }}
                  />
                )}
              </TimelineSeparator>

              <TimelineContent
                sx={{
                  pb: 2,
                }}
              >
                <TimelineEvent
                  status={_}
                  isSignedManual={isSignedManual}
                  timestamp={
                    _ === ProjectStatus.SIGNATURE_DONE
                      ? props.proposal.signedAt
                      : _ === ProjectStatus.SIGNATURE_IN_PROGRESS
                        ? props.proposal.signatureSentAt
                        : props.project.techDateModification
                  }
                >
                  {_ === ProjectStatus.MANAGEMENT_FAILED ? (
                    <Stack>
                      <Typography variant="caption">Action</Typography>
                      <Typography>
                        Veuillez transmettre le dossier de souscription par
                        email à{' '}
                        <Link href="mailto:ug55@mutuellegsmc.fr">
                          ug55@mutuellegsmc.fr
                        </Link>
                      </Typography>
                    </Stack>
                  ) : _ === ProjectStatus.SIGNATURE_IN_PROGRESS &&
                    props.proposal.signatureStatus !==
                      SignatureStatus.SIGNED ? (
                    <Stack justifyContent="flex-start">
                      <Typography variant="caption">Action</Typography>
                      {isSignatureExpired ? (
                        <Typography>
                          Le lien de signature électronique envoyé au prospect a
                          expiré le{' '}
                          {formatDate(props.proposal.signatureSentAt!)} à{' '}
                          {formatTime(props.proposal.signatureSentAt!)}
                        </Typography>
                      ) : (
                        <Typography>
                          Vous pouvez annuler le lien de signature.
                          <br />
                          Votre prospect ne pourra plus signer le projet avec le
                          lien qui lui a été envoyé.
                        </Typography>
                      )}
                      <Box sx={{ pt: 1 }}>
                        <Button
                          size="small"
                          disabled={signatureCancelMutation.isPending}
                          color={isSignatureExpired ? 'default' : 'error'}
                          onClick={() =>
                            signatureCancelMutation.mutate({
                              projectId: props.project.id,
                            })
                          }
                          sx={{
                            px: 4,
                          }}
                        >
                          {isSignatureExpired
                            ? 'Modifier le projet'
                            : 'Annuler la signature'}
                        </Button>
                      </Box>
                    </Stack>
                  ) : undefined}
                </TimelineEvent>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>

      {events.length > 1 && (
        <Box
          sx={{
            pb: 1,
            pl: 5,
          }}
        >
          <Link
            onClick={() =>
              setNbEvents(prev => (prev === undefined ? 1 : undefined))
            }
          >
            {nbEvents === undefined ? 'voir moins' : 'voir plus'}
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default ProjectTimeline;
