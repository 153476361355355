import React from 'react';

import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Icon } from 'components/Icon';
import { CloseIcon } from 'components/Icon/CloseIcon';
import { PersonCircleIcon } from 'components/Icon/PersonCircleIcon';
import CardPersonCheckbox from 'components/Card/CardPerson';
import { PersonDuoCircleIcon } from 'components/Icon/PersonDuoCircleIcon';
import { PersonTrioCircleIcon } from 'components/Icon/PersonTrioCircleIcon';
import { Offer } from 'models/referentiels/Offer.model';
import { Project } from 'models/oav/Project.models';
import { TransitionSlideUP } from 'utils/animations/transitions';

export interface DialogBeneficiariesProps {
  project: Project;
  offer?: Offer;
  beneficiaryIds: string[];
  onClick?: (beneficiaryId: string) => void;
  onClose?: () => void;
  onValidate: () => void;
  open: boolean;
}

export const DialogBeneficiaries: React.FC<DialogBeneficiariesProps> = ({
  project,
  beneficiaryIds,
  onClick,
  onClose,
  onValidate,
  open,
  offer,
}) => {
  const theme = useTheme();

  const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Dialog
      fullWidth
      fullScreen={!screenSizeUpSM}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      TransitionComponent={TransitionSlideUP}
    >
      <DialogTitle>
        <Typography variant="h2">
          Bénéficiaires{' '}
          <Chip
            label={`${beneficiaryIds.length} / ${1 + (project.partner ? 1 : 0) + (project.children?.length || 0)}`}
          />
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          ':hover': {
            backgroundColor: 'rgba(250, 250, 250, 0.04)',
          },
        }}
      >
        <Icon color={theme.palette.text.primary}>
          <CloseIcon />
        </Icon>
      </IconButton>
      <DialogContent>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <Alert
              icon={false}
              variant="outlined"
              severity="info"
              sx={{
                border: 2,
                borderColor: theme.palette.info.main,
                backgroundColor: alpha(theme.palette.info.main, 0.1),
              }}
            >
              <Typography variant="body2" component="span">
                {onClick ? 'Modifier les bénéficiaires ' : 'Bénéficiaires '}de
                la souscription à l&apos;offre
              </Typography>
              &nbsp;
              <Typography variant="body1" component="span">
                {offer?.label}
              </Typography>
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <Grid container gap={1}>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <PersonCircleIcon
                    size="large"
                    color={theme.palette.background.default}
                    bgColor={theme.palette.text.primary}
                  />
                  <Box>
                    <Typography>Votre Client</Typography>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <CardPersonCheckbox
                  person={project.subscriber.person}
                  selected
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>

          {project.partner?.id && (
            <Grid item xs={12}>
              <Grid container gap={1}>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <PersonDuoCircleIcon
                      size="large"
                      color={theme.palette.background.default}
                      bgColor={theme.palette.text.primary}
                    />
                    <Typography>Son conjoint</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <CardPersonCheckbox
                    person={project.partner.person}
                    selected={beneficiaryIds.includes(project.partner.id)}
                    disabled={onClick === undefined}
                    onClick={
                      onClick
                        ? () => onClick(project.partner?.id || '')
                        : undefined
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {project.children && (
            <Grid item xs={12}>
              <Grid container gap={1}>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <PersonTrioCircleIcon
                      size="large"
                      color={theme.palette.background.default}
                      bgColor={theme.palette.text.primary}
                    />
                    <Typography>Ses enfants</Typography>
                  </Stack>
                </Grid>
                {project.children.map(_ => {
                  if (!_.id) return;

                  const selected = beneficiaryIds.includes(_.id);
                  return (
                    <Grid key={_.id} item xs={12}>
                      <CardPersonCheckbox
                        person={_.person}
                        selected={selected}
                        disabled={onClick === undefined}
                        onClick={
                          onClick ? () => onClick(_.id || '') : undefined
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
        }}
      >
        {onClick ? (
          <>
            <Button variant="text" color="default" onClick={onClose}>
              Annuler
            </Button>
            <Button onClick={onValidate} sx={{ px: 4 }}>
              Valider
            </Button>
          </>
        ) : (
          <Button onClick={onClose} sx={{ px: 4 }}>
            Fermer
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
