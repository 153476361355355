import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { appName } from 'App';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DialogSignatureUpload from 'content/project/signature/DialogSignatureUpload';
import DialogSignatureDigital from 'content/project/signature/DialogSignatureDigital';
import PreviewDocument from 'content/project/supportingDocument/PreviewDocument';
import CardSubscription from 'content/subscription/CardSubscription';
import SubscriptionSummary from 'content/subscription/SubscriptionSummary';

import CardError from 'components/Card/CardError';
import { ChipProjectStatus } from 'components/Chip/ChipProjectStatus';

import { ProjectStep } from 'models/oav/ProjectStep.models';
import { Subscription } from 'models/oav/Subscription.models';

import { createCommercialProposition } from 'api/oav/CommercialProposition.api';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';

import { navigateToPreviousStep } from 'utils/project/project';
import { RestError } from 'errors/RestError';
import { useAccessToken } from 'utils/api/api';

const CURRENT_STEP = ProjectStep.SIGNATURE;

const ProjectSignaturePage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const accessToken = useAccessToken();

  if (!id) return <></>;

  const projectQuery = useQuery(projectQueries.getById(id));
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(id));

  const getSubscriptionFromOfferCode = (code: string) => {
    const subscriptions = projectQuery.data?.subscriptions;
    if (!subscriptions) return;

    const sub = subscriptions.find(_ => _.offerCode === code);
    if (sub) return sub;
    else if (subscriptions.length > 0) return subscriptions[0];
  };

  const [subscriptionCurrent, setSubscriptionCurrent] = useState<
    Subscription | undefined
  >(getSubscriptionFromOfferCode(searchParams.get('offre') as string));

  useEffect(() => {
    setSubscriptionCurrent(
      getSubscriptionFromOfferCode(searchParams.get('offre') as string),
    );
  }, [projectQuery?.data]);

  useEffect(() => {
    if (!subscriptionCurrent?.offerCode) return;
    setSearchParams(prev => ({
      ...prev,
      offre: subscriptionCurrent.offerCode,
    }));
  }, [subscriptionCurrent?.offerCode]);

  const offer = useMemo(() => {
    if (subscriptionCurrent && offersMapQuery.data) {
      return offersMapQuery.data[subscriptionCurrent.offerCode];
    }
  }, [subscriptionCurrent, offersMapQuery.data]);

  const solutionQuery = useQuery({
    ...subscriptionQueries.getByIdSolution(
      id,
      subscriptionCurrent?.id || '',
    ),
    enabled: !!subscriptionCurrent?.id,
  });

  const proposalQuery = useQuery({
    queryKey: ['projects', id, 'proposal'],
    queryFn: () => createCommercialProposition(accessToken, id, { sendEmail: false }),
    staleTime: 0,
    gcTime: 0,
  });

  const [showDialogSignManual, setShowDialogSignManual] = useState<boolean>(false);
  const [showDialogSignAuto, setShowDialogSignAuto] = useState<boolean>(false);

  if (projectQuery.isError || offersMapQuery.isError) {
    return (
      <Grid item xs={12} sx={{ height: 200 }}>
        <CardError
          status={
            projectQuery.error instanceof RestError
              ? projectQuery.error.status
              : undefined
          }
        />
      </Grid>
    );
  }

  if (
    !projectQuery.data ||
    !offersMapQuery.data ||
    !subscriptionCurrent ||
    !offer
  ) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">Signature</Typography>
      </Grid>

      <Grid item xs={12}>
        <Card
          sx={{ p: 2 }}>
          <Stack
            direction={{
              sm: 'row',
              xs: 'column-reverse'
            }}
            alignItems={{
              sm: 'center',
              xs: 'flex-start'
            }}
            spacing={2}>
            <Typography sx={{ width: '100%' }}>
              Votre projet de souscription est complet et peut être signé par votre client.
            </Typography>
            {projectQuery.data.status && <ChipProjectStatus projectStatus={projectQuery.data.status} />}
          </Stack>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <CardSubscription
          project={projectQuery.data}
          subscription={subscriptionCurrent}
          offer={offer}>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12} height="600px">
              {proposalQuery.error && <CardError />}
              {proposalQuery.isFetching && <Skeleton height="100%" width="100%" sx={{ transform: 'unset' }} />}
              {proposalQuery.data &&
                <PreviewDocument
                  document={proposalQuery.data}
                  loading={proposalQuery.isLoading} />}
            </Grid>

            <Grid item md={4} xs={12}>
              <SubscriptionSummary
                project={projectQuery.data}
                subscription={subscriptionCurrent}
                solution={solutionQuery.data}
                offer={offer}
                showDate
                showBeneficiaries
                showSolution />
            </Grid>
          </Grid>
        </CardSubscription>
      </Grid>

      <Grid item sm="auto" xs={12} >
        <Button
          fullWidth
          color="default"
          onClick={() => navigateToPreviousStep(CURRENT_STEP, id, navigate)}
          sx={{ px: 4 }}>
          Retour
        </Button>
      </Grid >

      <Grid item sm="auto" xs={12}>
        <DialogSignatureDigital
          open={showDialogSignAuto}
          project={projectQuery.data}
          onClose={() => setShowDialogSignAuto(false)}
        />
        <Button
          fullWidth
          disabled={!proposalQuery.data}
          onClick={() => setShowDialogSignAuto(true)}
          sx={{ px: 4 }}>
          Signature électronique
        </Button>
      </Grid>

      <Grid item sm="auto" xs={12}>
        <DialogSignatureUpload
          project={projectQuery.data}
          open={showDialogSignManual}
          onClose={() => setShowDialogSignManual(false)}
        />
        <Button
          fullWidth
          disabled={!proposalQuery.data}
          onClick={() => setShowDialogSignManual(true)}
          sx={{
            px: 4,
          }}>
          Signature manuelle
        </Button>
      </Grid>
    </Grid >
  );
};

export default ProjectSignaturePage;
