import { cloneElement, useMemo, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';

import {
  formatBytes,
  getMimeTypeFromExtension,
  MimeType,
  mimeTypeDetails,
  MimeTypeDetails,
} from 'utils/document/documentUtils';

export type CardDocumentDownloadProps = {
  type?: string;
  name: string;
  description?: string;
  size?: number;
  showDetail?: boolean;
  onClick?: () => void;
};

const CardDocumentDownload = (props: CardDocumentDownloadProps) => {
  const theme = useTheme();

  const extension: string | undefined = props.name.split('.')?.pop();
  const [isLoading, setIsLoading] = useState(false);

  const mimeType = useMemo<MimeType | undefined>(() => {
    if (props.type)
      return Object.values(MimeType).find(
        mimeType => mimeType === props.type,
      ) as MimeType | undefined;
    if (extension) return getMimeTypeFromExtension(extension);
  }, [extension, props.type]);
  const mimeTypeDetail = useMemo<MimeTypeDetails | undefined>(() => {
    if (mimeType) return mimeTypeDetails[mimeType];
  }, [mimeType]);

  const handleClick = async () => {
    setIsLoading(true);

    try {
      await props.onClick?.();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card
      onClick={() => handleClick()}
      sx={{
        position: 'relative',
        p: 2,
        height: '100%',
        cursor: props.onClick ? 'pointer' : 'default',
        transition: 'transform 150ms ease-in-out',
        boxShadow: theme.shadows[3],
        ':hover': {
          transform: props.onClick === undefined ? 'none' : 'translateY(-6px)',
        },
      }}
    >
      {isLoading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
            background: theme.palette.background.paper,
          }}
        />
      )}

      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>{props.name}</Typography>
              {props.description && (
                <Typography variant="caption">{props.description}</Typography>
              )}
            </Grid>

            {props.showDetail && (
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {mimeTypeDetail?.icon &&
                    cloneElement(mimeTypeDetail?.icon, {
                      sx: { width: 16, height: 16 },
                    })}
                  {props.size && (
                    <Typography
                      variant="caption"
                      sx={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {formatBytes(props.size)}
                    </Typography>
                  )}
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs="auto">
          <DownloadIcon sx={{ display: 'flex' }} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default CardDocumentDownload;
