import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { AddCircleIcon } from 'components/Icon/AddCircleIcon';

export type CardSolutionNewProps = {
  disabled?: boolean;
  onClick?: () => void;
};

const CardSolutionNew = (props: CardSolutionNewProps) => {
  const theme = useTheme();

  const enabled = !props.disabled && props.onClick;

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        width: '100%',
        height: '100%',
        p: 2,
        borderRadius: 4,
        transition: 'transform 150ms ease-in-out',
        cursor: enabled ? 'pointer' : 'default',
        backgroundImage: `url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='${encodeURIComponent(theme.palette.text.secondary)}' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")`,
        ':hover': {
          transform: enabled ? 'translateY(-6px)' : 'none',
        },
      }}
      onClick={() => !props.disabled && props.onClick?.()}
    >
      <Stack
        alignItems="center"
        spacing={1}
        sx={{
          textAlign: 'center',
        }}
      >
        <AddCircleIcon size="large" />
        <Typography>Ajouter une solution</Typography>
      </Stack>
    </Card>
  );
};

export default CardSolutionNew;
