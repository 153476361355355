import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { useAppContext } from 'components/Context/AppContext';
import CardError from 'components/Card/CardError';
import PriceNumber from 'content/formula/PriceNumber';
import CardOfferDetail from 'content/offer/CardOfferDetail';
import CardSolution from 'content/solution/CardSolution';

import { projectQueries } from 'api/oav/ProjectQuery.api';
import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';
import { solutionMutations } from 'api/oav/SolutionQuery.api';

import { Subscription } from 'models/oav/Subscription.models';
import { Project } from 'models/oav/Project.models';
import { Offer } from 'models/referentiels/Offer.model';
import {
  Solution,
  SolutionFormula,
  SolutionItem,
  SolutionItemRequest,
  SolutionOffer,
  TarificationPeriod,
} from 'models/oav/Solution.models';
import { TrfPricing } from 'models/referentiels/Tarification.models';
import { Formula } from 'models/referentiels/Formula.model';

import {
  OfferFormulaTarificationMap,
  tarificationToMap,
  toOffersFormulasTarificationMap,
} from 'utils/tarification/tarification';
import { TransitionSlideUP } from 'utils/animations/transitions';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';

type DialogSolutionEditionContentProps = {
  project: Project;
  subscription: Subscription;
  onClose: () => void;
  solutionOrder: number;
  initialSolution?: Solution;
};

type OfferFormValue = {
  formulaCode?: string;
  offer: Offer;
};

type FormValues = {
  base: OfferFormValue;
  options?: OfferFormValue[];
  reinforcements?: OfferFormValue[];
};

const offerFormValueToSolutionItemRequest = (
  value: OfferFormValue,
): SolutionItemRequest => ({
  offerCode: value.offer.code,
  formulaCode: value.formulaCode!,
});

const offerToSolutionOffer = (offer: Offer): SolutionOffer => ({
  code: offer.code,
  label: offer.label,
  description: offer.description,
});

const offerFormValueToSolutionItem = (
  value: OfferFormValue,
  offerFormulaTrfMap: OfferFormulaTarificationMap,
): SolutionItem => {
  const f = value.formulaCode
    ? offerFormulaTrfMap?.[value.offer.code]?.[value.formulaCode]
    : undefined;
  return {
    offer: offerToSolutionOffer(value.offer),
    formula: formulaToSolutionFormula(
      f ? formulaToSolutionFormula(f.formula) : undefined,
    ),
    pricing: f?.pricing,
  };
};

const formulaToSolutionFormula = (formula?: Formula): SolutionFormula => {
  return {
    code: formula?.code ?? '',
    color: formula?.color ?? '',
    label: formula?.label ?? '',
    description: formula?.description ?? '',
  };
};

const incrementPricing = (solutionItem: SolutionItem, pricing: TrfPricing) => {
  if (solutionItem.pricing) {
    pricing.perYear = pricing.perYear + solutionItem.pricing.perYear;
    pricing.perMonth = pricing.perMonth + solutionItem.pricing.perMonth;
  }
};

const calculateSolutionPricing = (solution: Solution): TrfPricing => {
  const pricing: TrfPricing = {
    currency: 'EUR',
    perMonth: 0,
    perYear: 0,
  };

  incrementPricing(solution.base, pricing);
  solution.options?.forEach(option => incrementPricing(option, pricing));
  solution.reinforcements?.forEach(r => incrementPricing(r, pricing));

  return pricing;
};

const DialogSolutionEditionContent: React.FC<
  DialogSolutionEditionContentProps
> = ({ project, subscription, onClose, solutionOrder, initialSolution }) => {
  const id = project.id;

  const theme = useTheme();
  const { addSnackbar } = useAppContext();

  const mutationOptions = {
    onSuccess: () => {
      onClose();
    },
    onError: () => {
      addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    },
  };

  const createMutation = solutionMutations.useCreateSolution(mutationOptions);
  const updateMutation = solutionMutations.useUpdateSolution(mutationOptions);
  const isPending = createMutation.isPending || updateMutation.isPending;

  const [period, setPeriod] = useState<TarificationPeriod>(
    TarificationPeriod.MONTHLY,
  );

  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(id));

  const tarificationsQuery = useQuery({
    ...subscriptionQueries.getByIdTarification(id, subscription?.id || ''),
    enabled: !!subscription?.id,
  });

  const tarificationMap = useMemo(
    () => tarificationToMap(tarificationsQuery.data),
    [tarificationsQuery.data],
  );

  const offer = offersMapQuery.data
    ? offersMapQuery.data[subscription.offerCode]
    : undefined;

  const offerFormulaTarificationMap = useMemo(
    () => toOffersFormulasTarificationMap(offer, tarificationMap),
    [offer, tarificationMap],
  );

  const refFormik = useRef<FormikProps<FormValues>>(null);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const request = {
        projectId: project.id,
        body: {
          subscriptionId: subscription.id,
          order: solutionOrder,
          base: offerFormValueToSolutionItemRequest(values.base),
          options: values.options
            ?.filter(_ => !!_.formulaCode)
            .map(_ => offerFormValueToSolutionItemRequest(_)),
          reinforcements: values.reinforcements
            ?.filter(_ => !!_.formulaCode)
            .map(_ => offerFormValueToSolutionItemRequest(_)),
        },
      };

      if (initialSolution) {
        updateMutation.mutate({ ...request, id: initialSolution.id });
      } else {
        createMutation.mutate(request);
      }
    },
    [project, subscription],
  );

  if (offersMapQuery.error) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          height: 200,
        }}
      >
        <CardError />
      </Grid>
    );
  }
  if (!offersMapQuery.data || !offer) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  const initialValues = {
    base: {
      offer,
      formulaCode: initialSolution?.base?.formula?.code,
    },
    reinforcements: offer.reinforcements?.map(_ => ({
      offer: _,
      formulaCode: initialSolution?.reinforcements?.find(
        r => r.offer.code === _.code,
      )?.formula?.code,
    })),
    options: offer.options?.map(_ => ({
      offer: _,
      formulaCode: initialSolution?.options?.find(o => o.offer.code === _.code)
        ?.formula?.code,
    })),
  };

  const validationSchemaOffer = Yup.object().shape({
    formulaCode: Yup.string().required('Sélection obligatoire'),
  });
  const validationSchemaOfferOption = Yup.object().shape({
    formulaCode: Yup.string(),
  });
  const validationSchema = Yup.object().shape({
    base: validationSchemaOffer,
    reinforcements: Yup.array().of(validationSchemaOfferOption),
    options: Yup.array().of(validationSchemaOfferOption),
  });

  return (
    <>
      <Formik
        innerRef={refFormik}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
          const solution = useMemo<Solution>(() => {
            const solution: Solution = {
              base: offerFormValueToSolutionItem(
                values.base,
                offerFormulaTarificationMap,
              ),
              options: values.options?.map(_ =>
                offerFormValueToSolutionItem(_, offerFormulaTarificationMap),
              ),
              reinforcements: values.reinforcements?.map(_ =>
                offerFormValueToSolutionItem(_, offerFormulaTarificationMap),
              ),
              id: initialSolution?.id ?? '',
              order: solutionOrder,
            };

            solution.pricing = calculateSolutionPricing(solution);

            return solution;
          }, [values, tarificationMap]);

          return (
            <Box
              sx={{
                display: 'flex',
                flexWrap: {
                  md: 'nowrap',
                  xs: 'wrap',
                },
                height: '100%',
                overflowY: 'auto',
                gap: 2,
                px: 2,
              }}
            >
              <Box
                flexGrow={1}
                sx={{
                  width: '100%',
                  height: 'max-content',
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    py: 2,
                  }}
                >
                  <Grid id={offer.code} item xs={12}>
                    <CardOfferDetail
                      enableWarranties
                      offer={offer}
                      tarification={tarificationsQuery.data}
                      error={errors.base?.formulaCode !== undefined}
                      helperText={touched.base && errors.base?.formulaCode}
                      recommendedFormulaCode={
                        subscription.recommendedFormulaCode
                      }
                      selectedFormula={values.base.formulaCode}
                      onFormulaSelected={(formula, selected) => {
                        setFieldValue(
                          'base',
                          { ...values.base, formulaCode: selected ? formula.code : undefined }
                        );
                        setFieldTouched('base', true, false);
                        if (selected) {
                          values.reinforcements?.forEach((r, index) => {
                            if (
                              r.formulaCode &&
                              (r.offer.formulas[0].formulasComboCode?.length ??
                                0 > 0)
                            ) {
                              const newFormulaCode = r.offer.formulas.find(_ =>
                                _.formulasComboCode?.includes(formula.code),
                              )?.code;
                              setFieldValue(
                                `reinforcements.${index}.formulaCode`,
                                newFormulaCode, false
                              );
                            }
                          });
                        }
                      }}
                    />
                  </Grid>

                  {values.reinforcements && (
                    <>
                      <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography variant="h3">Renforts</Typography>
                          <Typography variant="h3">
                            <Chip label={values.reinforcements.length} />
                          </Typography>
                        </Stack>
                      </Grid>

                      {values.reinforcements.map((_, index) => {
                        return (
                          <Grid
                            id={_.offer.code}
                            key={_.offer.code}
                            item
                            xs={12}
                          >
                            <CardOfferDetail
                              enableWarranties
                              offer={_.offer}
                              tarification={tarificationMap[_.offer.code]}
                              baseFormulas={values.base.offer.formulas}
                              selectedFormula={_.formulaCode}
                              selectableFormulas={
                                values.base.formulaCode &&
                                  (_.offer.formulas[0].formulasComboCode
                                    ?.length ??
                                    0 > 0)
                                  ? _.offer.formulas
                                    .filter(_ =>
                                      _.formulasComboCode?.includes(
                                        values.base.formulaCode!,
                                      ),
                                    )
                                    .map(_ => _.code)
                                  : undefined
                              }
                              onFormulaSelected={(formula, selected) => {
                                setFieldValue(
                                  `reinforcements.${index}.formulaCode`,
                                  selected ? formula.code : undefined,
                                );
                                setFieldTouched(`reinforcements.${index}`, true, false);
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </>
                  )}

                  {values.options && (
                    <>
                      <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Typography variant="h3">Options</Typography>
                          <Typography variant="h3">
                            <Chip label={values.options.length} />
                          </Typography>
                        </Stack>
                      </Grid>

                      {values.options.map((_, index) => {
                        return (
                          <Grid
                            id={_.offer.code}
                            key={_.offer.code}
                            item
                            xs={12}
                          >
                            <CardOfferDetail
                              enableWarranties
                              offer={_.offer}
                              tarification={tarificationMap[_.offer.code]}
                              selectedFormula={_.formulaCode}
                              onFormulaSelected={(formula, selected) => {
                                setFieldValue(
                                  `options.${index}.formulaCode`,
                                  selected ? formula.code : undefined,
                                );
                                setFieldTouched(`options.${index}`, true, false);
                              }}
                            />
                          </Grid>
                        );
                      })}
                    </>
                  )}
                </Grid>
              </Box>

              <Box
                sx={{
                  zIndex: 2000,
                  position: 'sticky',
                  top: {
                    md: 0,
                    xs: 'none',
                  },
                  bottom: {
                    md: 'none',
                    xs: 0,
                  },
                  width: {
                    md: '300px',
                    xs: '100%',
                  },
                  pt: {
                    md: 2,
                    xs: 0,
                  },
                  pb: 2,
                  background: theme.palette.background.default,
                  borderTop: {
                    md: 'none',
                    xs: `1px solid ${theme.palette.divider}`,
                  },
                }}
              >
                <Stack
                  justifyContent="space-between"
                  gap={2}
                  sx={{
                    height: '100%',
                  }}
                >
                  <Stack
                    sx={{
                      display: {
                        md: 'inline-flex',
                        xs: 'none',
                      },
                    }}
                  >
                    <CardSolution
                      period={period}
                      solution={solution}
                      onPeriodChange={setPeriod}
                      onClickItem={_ => {
                        const element = document.getElementById(_.offer.code);
                        if (element) {
                          element.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                          });
                        }
                      }}
                    />

                    {tarificationsQuery.data?.disclaimers
                      ?.tarificationValidity && (
                        <Typography
                          variant="caption"
                          sx={{
                            pt: 2,
                          }}
                        >
                          ⚠️{' '}
                          {
                            tarificationsQuery.data?.disclaimers
                              ?.tarificationValidity
                          }
                        </Typography>
                      )}
                  </Stack>

                  <Box
                    sx={{
                      containerType: 'inline-size',
                      display: {
                        md: 'none',
                        xs: 'inline',
                      },
                      py: 1,
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      flexGrow={1}
                      sx={{
                        '@container (max-width: 200px)': {
                          textAlign: 'right',
                        },
                      }}
                    >
                      Solution n°{solutionOrder}
                    </Typography>
                    <Stack
                      direction="column"
                      sx={{
                        containerType: 'inline-size',
                        width: '100%',
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        flexWrap="wrap"
                        spacing={1}
                      >
                        <Typography variant="caption">par mois</Typography>
                        <Typography
                          sx={{
                            flexGrow: 1,
                            textAlign: 'right',
                            '@container (max-width: 200px)': {
                              width: '100%',
                            },
                          }}
                        >
                          <PriceNumber price={solution.pricing?.perMonth} />
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        flexWrap="wrap"
                        spacing={1}
                      >
                        <Typography variant="caption">par an</Typography>
                        <Typography
                          sx={{
                            flexGrow: 1,
                            textAlign: 'right',
                            '@container (max-width: 200px)': {
                              width: '100%',
                            },
                          }}
                        >
                          <PriceNumber price={solution.pricing?.perYear} />
                        </Typography>
                      </Stack>

                      {tarificationsQuery.data?.disclaimers
                        ?.tarificationValidity && (
                          <Typography
                            variant="caption"
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            ⚠️{' '}
                            {
                              tarificationsQuery.data?.disclaimers
                                ?.tarificationValidity
                            }
                          </Typography>
                        )}
                    </Stack>
                  </Box>

                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item md={12} sm="auto" xs>
                      <Button
                        variant="text"
                        color="default"
                        disabled={isPending}
                        onClick={onClose}
                        sx={{
                          width: {
                            md: '100%',
                            sm: 'auto',
                            xs: '100%',
                          },
                          px: 4,
                        }}
                      >
                        Retour
                      </Button>
                    </Grid>

                    <Grid item md={12} sm="auto" xs>
                      <Button
                        disabled={isPending}
                        onClick={() => {
                          refFormik.current?.submitForm();
                        }}
                        endIcon={
                          isPending ? (
                            <CircularProgress color="inherit" size={26} />
                          ) : undefined
                        }
                        sx={{
                          width: {
                            md: '100%',
                            sm: 'auto',
                            xs: '100%',
                          },
                          px: 4,
                        }}
                      >
                        Valider
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};

type DialogSolutionEditionProps = {
  open: boolean;
  onClose: () => void;
  project: Project;
  subscription: Subscription;
  solutionOrder: number;
  initialSolution?: Solution;
};

export const DialogSolutionEdition: FC<DialogSolutionEditionProps> = ({
  open,
  onClose,
  project,
  subscription,
  solutionOrder,
  initialSolution,
}) => {
  const theme = useTheme();

  const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Dialog
      fullWidth
      fullScreen={!screenSizeUpSM}
      open={open}
      TransitionComponent={TransitionSlideUP}
      PaperProps={{
        sx: {
          height: '100%',
          maxWidth: '1400px',
        },
      }}
    >
      <DialogSolutionEditionContent
        project={project}
        subscription={subscription}
        onClose={onClose}
        solutionOrder={solutionOrder}
        initialSolution={initialSolution}
      />
    </Dialog>
  );
};

export default DialogSolutionEdition;
