import { RestError } from 'errors/RestError';
import {
  CommercialProposition,
  CommercialPropositionRequest,
} from 'models/oav/CommercialProposition.models';
import {
  ContractualDocument,
  ContractualDocumentDownload,
} from 'models/oav/ContractualDocument.models';
import { getRequest } from 'utils/queries/queries.utils';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const createCommercialProposition = async (
  accessToken: string,
  projectId: string,
  payload: CommercialPropositionRequest,
): Promise<CommercialProposition> => {
  const url = `${baseUrl}/${projectId}/commercial-proposition`;

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'POST',
      JSON.stringify(payload),
      await response.json(),
    );

  return mapCommercialProposition(await response.json());
};

export const getCommercialProposition = async (
  accessToken: string,
  projectId: string,
) => {
  return mapCommercialProposition(
    await getRequest(
      `${baseUrl}/${projectId}/commercial-proposition`,
      accessToken,
    ),
  );
};

export const getContractualDocument = async (
  accessToken: string,
  projectId: string,
  documentId: string,
) => {
  return mapContractualDocumentDownload(
    await getRequest(
      `${baseUrl}/${projectId}/commercial-proposition/contractual-documents/${documentId}`,
      accessToken,
    ),
  );
};

const mapCommercialProposition = (response: any): CommercialProposition => ({
  ...response,
  signatureSentAt:
    response.signatureSentAt && new Date(response.signatureSentAt),
  signatureExpireAt:
    response.signatureExpireAt && new Date(response.signatureExpireAt),
  contractualDocuments: response.contractualDocuments.map((_: any) =>
    mapContractualDocument(_),
  ),
});

const mapContractualDocument = (response: any): ContractualDocument => ({
  ...response,
});

const mapContractualDocumentDownload = (
  response: any,
): ContractualDocumentDownload => ({
  ...response,
  ...mapContractualDocument(response),
});
