import React, { Fragment, useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CardOfferLink from 'content/offer/CardOfferLink';
import CardBeneficiaryLink from 'components/Card/CardBeneficiaryLink';
import { useProjectContext } from 'components/Context/ProjectContext';
import { CheckCircleFilledIcon } from 'components/Icon/CheckCircleFilledIcon';
import { CheckCircleOutlinedBlankIcon } from 'components/Icon/CheckCircleOutlinedBlankIcon';
import { getSize } from 'components/Icon/settings';
import CardSolutionLink from 'content/solution/CardSolutionLink';
import ChipPeriod from 'content/solution/ChipPeriod';

import { Project } from 'models/oav/Project.models';
import { Subscription } from 'models/oav/Subscription.models';
import { OffersMap } from 'models/referentiels/Offer.model';
import {
  isStepDisabled,
  isStepDone,
  ProjectStep,
  projectStepsData,
} from 'models/oav/ProjectStep.models';
import { TarificationPeriod } from 'models/oav/Solution.models';
import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';

const MenuStep = (props: {
  label: string;
  to: string;
  endElement?: JSX.Element;
  done?: boolean;
  active?: boolean;
  disabled?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {props.done ? (
        <CheckCircleFilledIcon
          size="small"
          style={{
            color: theme.palette.success.main,
          }}
        />
      ) : (
        <CheckCircleOutlinedBlankIcon size="small" />
      )}
      <Link
        to={props.to}
        style={{ pointerEvents: props.disabled ? 'none' : 'auto', flexGrow: 1 }}
      >
        <Card
          sx={{
            px: 1,
            borderRadius: 2,
            color: props.disabled
              ? theme.palette.text.disabled
              : props.active
                ? theme.palette.primary.main
                : theme.palette.text.primary,
            background: props.active
              ? theme.palette.primary.main + '30'
              : 'none',
            ':hover': {
              background: props.disabled
                ? 'none'
                : props.active
                  ? theme.palette.primary.main + '30'
                  : theme.palette.primary.main + '20',
            },
          }}
        >
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>{props.label}</Typography>
            {props.endElement}
          </Stack>
        </Card>
      </Link>
    </Stack>
  );
};

const MenuStepContent = (props: { children: any }) => {
  return (
    <Stack direction="row" spacing={1}>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: `${getSize('small').width}px`,
          }}
        >
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              m: 0,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          width: 'calc(100% - 64px)',
        }}
      >
        {props.children}
      </Box>
    </Stack>
  );
};

export type MenuProjectProps = {
  project: Project;
  offersMap: OffersMap;
};

const MenuProject: React.FC<MenuProjectProps> = (props: MenuProjectProps) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const context = useProjectContext();
  const { dataCurrent } = context;

  const project = dataCurrent.project || props.project;

  const [period, setPeriod] = useState<TarificationPeriod>(
    TarificationPeriod.MONTHLY,
  );

  // TODO: handle multi subscription
  const subscription = project.subscriptions?.[0];
  const solutionsQuery = useQuery({
    ...subscriptionQueries.getByIdSolutions(project.id, subscription?.id || ''),
    enabled:
      !!subscription?.id &&
      (project.currentStep
        ? projectStepsData[project.currentStep]!.order
        : 0) >= projectStepsData[ProjectStep.SOLUTIONS]!.order,
  });

  const estimationDisabled =
    (project.currentStep ? projectStepsData[project.currentStep]!.order : 0) <
      projectStepsData[ProjectStep.SOLUTIONS]!.order ||
    (solutionsQuery.data?.length ?? 0) === 0;

  const commercialPropositionDisabled =
    (project.currentStep ? projectStepsData[project.currentStep]!.order : 0) <
      projectStepsData[ProjectStep.SOLUTION_SELECTION]!.order ||
    (solutionsQuery.data?.length ?? 0) === 0;

  return (
    <Stack>
      {Object.entries(projectStepsData)
        .sort(([, dataA], [, dataB]) => dataA.order - dataB.order)
        .map(([_, data]) => {
          const step = _ as ProjectStep;
          const disabled = isStepDisabled(step, project.currentStep);
          const done = isStepDone(step, project.currentStep);
          const endElement =
            step === ProjectStep.SUBSCRIPTIONS ? (
              <Chip
                label={project.subscriptions?.length.toString() || '0'}
                size="small"
              />
            ) : step === ProjectStep.CLIENTS_INFO ? (
              <Chip
                label={`${
                  (project.children?.length || 0) +
                  (project.subscriber ? 1 : 0) +
                  (project.partner ? 1 : 0)
                }`}
                size="small"
              />
            ) : step === ProjectStep.SOLUTIONS ? (
              <Chip label={solutionsQuery.data?.length} size="small" />
            ) : undefined;

          return (
            <Fragment key={_}>
              <MenuStep
                label={data.label}
                to={data.path}
                done={done}
                disabled={disabled}
                active={matchPath(data.pathMatch, pathname) !== null}
                endElement={endElement}
              />

              {step === ProjectStep.SUBSCRIPTIONS ? (
                <MenuStepContent>
                  {project.subscriptions?.map((_: Subscription) => {
                    const offer = props.offersMap[_.offerCode];
                    if (!offer) return;
                    return <CardOfferLink key={_.offerCode} offer={offer} />;
                  })}
                </MenuStepContent>
              ) : step === ProjectStep.CLIENTS_INFO ? (
                <MenuStepContent>
                  {project.subscriber && (
                    <CardBeneficiaryLink
                      key="souscripteur"
                      to="donnees-clients#souscripteur"
                      beneficiary={project.subscriber}
                      disabled={disabled}
                    />
                  )}
                  {project.partner && (
                    <CardBeneficiaryLink
                      key="conjoint"
                      to="donnees-clients#conjoint"
                      beneficiary={project?.partner}
                      disabled={disabled}
                    />
                  )}
                  {project?.children !== undefined &&
                    project.children.map((_, index: number) => (
                      <CardBeneficiaryLink
                        key={`enfant${index + 1} `}
                        to={`donnees-clients#enfant${index + 1}`}
                        beneficiary={_}
                        disabled={disabled}
                      />
                    ))}
                </MenuStepContent>
              ) : step === ProjectStep.SOLUTIONS ? (
                <MenuStepContent>
                  {/* TODO : Manage multi-offer */}
                  {solutionsQuery.data && (
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        width: '100%',
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          color: theme.palette.text.primary,
                        }}
                      >
                        {props.offersMap[subscription?.offerCode || '']
                          ?.label || ''}
                      </Typography>
                      <ChipPeriod period={period} onChange={setPeriod} />
                    </Stack>
                  )}
                  {solutionsQuery.data?.map(_ => {
                    return (
                      <CardSolutionLink
                        key={_.id}
                        selected={subscription?.solutionId === _.id}
                        solution={_}
                        period={period}
                      />
                    );
                  })}
                  <Button
                    sx={{
                      marginY: 1,
                    }}
                    onClick={context.openEstimationDialog}
                    fullWidth
                    size="small"
                    disabled={estimationDisabled}
                  >
                    Éditer un devis
                  </Button>
                </MenuStepContent>
              ) : step === ProjectStep.SOLUTION_SELECTION ? (
                <MenuStepContent>
                  <Button
                    sx={{
                      marginY: 1,
                    }}
                    onClick={context.openCommercialPropositionDialog}
                    fullWidth
                    size="small"
                    disabled={commercialPropositionDisabled}
                  >
                    Éditer un projet
                  </Button>
                </MenuStepContent>
              ) : (
                <></>
              )}
            </Fragment>
          );
        })}
    </Stack>
  );
};

export default MenuProject;
