import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import * as Yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, FormikProps } from 'formik';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import {
  NeededSupportingDocument,
  SupportingDocument,
} from 'models/oav/SupportingDocument.models';
import ButtonClose from 'components/Button/ButtonClose';
import { DropzoneDocument } from 'components/Dropzone/DropzoneDocument';
import { useUploadSupportingDocuments } from 'utils/project/supportingDocuments';
import CardSupportingDocuments from 'content/project/supportingDocument/CardSupportingDocuments';
import PreviewDocument from 'content/project/supportingDocument/PreviewDocument';
import { Project } from 'models/oav/Project.models';
import {
  Beneficiary,
  BeneficiaryType,
  UpdateBeneficiariesRequestErrors,
  UpdateBeneficiaryRequest,
} from 'models/oav/Beneficiary.models';
import { messageFieldRequired } from 'utils/validation/message';
import {
  getDateFromAge,
  yupBirthOrder,
  yupCpamId,
  yupNni,
} from 'utils/validation/yupPerson';
import FormCPAMBeneficiary from 'content/project/supportingDocument/FormCPAMBeneficiary';
import { mutations } from 'api/oav/BeneficiaryQuery.api';
import { handleMutationError, iterateListErrors } from 'utils/api/api';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { setFormikError } from 'utils/fields/fields';
import { useAppContext } from 'components/Context/AppContext';
import { getBeneficiariesLinkedToSubscription } from 'utils/project/project';
import Stack from '@mui/material/Stack';
import {
  DEFAULT_FILE_ACCEPT,
  DEFAULT_FILE_MAX_SIZE,
} from 'utils/document/dropZoneUtils';

export interface BeneficiaryValues {
  nni: string;
  cpamId: string;
  remoteTransmission: boolean;
  canBeAttached: boolean;
  birthOrder: number;
  attachment:
  | BeneficiaryType.PARTNER
  | BeneficiaryType.SUBSCRIBER
  | 'OTHER'
  | null;
  beneficiaryContext: Beneficiary;
}

export interface CPAMFormikValues {
  beneficiaries: BeneficiaryValues[];
}

const getInitialValues = (
  subscriber: Beneficiary,
  partner?: Beneficiary,
  children?: Beneficiary[],
  startDate?: Date,
) => {
  const beneficiaries: BeneficiaryValues[] = [beneficiaryToValues(subscriber)];
  partner && beneficiaries.push(beneficiaryToValues(partner));
  children?.forEach(_ =>
    beneficiaries.push(beneficiaryToValues(_, subscriber, partner, startDate)),
  );
  return {
    beneficiaries,
  };
};

const canBeAttached = (beneficiary: Beneficiary, startDate?: Date) => {
  const birthDate = new Date(beneficiary.person.birthdate as Date);
  return !!(
    (beneficiary.type === BeneficiaryType.CHILD)
    && startDate
    && (birthDate.getTime() || 0) - getDateFromAge(18, startDate).getTime() > 0

  );
};

const beneficiaryToValues = (
  beneficiary: Beneficiary,
  subscriber?: Beneficiary,
  partner?: Beneficiary,
  startDate?: Date,
): BeneficiaryValues => {
  const nni = beneficiary.person?.nni ?? '';
  let attachment: BeneficiaryValues['attachment'] = null;
  const beneficiaryCanBeAttached = canBeAttached(beneficiary, startDate);
  if (beneficiaryCanBeAttached) {
    if (subscriber?.person?.nni === nni) {
      attachment = BeneficiaryType.SUBSCRIBER;
    } else if (partner?.person?.nni === nni) {
      attachment = BeneficiaryType.PARTNER;
    } else if (nni.length > 0) {
      attachment = 'OTHER';
    }
  }

  return {
    canBeAttached: beneficiaryCanBeAttached,
    nni,
    cpamId: beneficiary.person.cpamId ?? '',
    birthOrder: beneficiary.person.birthOrder ?? 1,
    remoteTransmission: beneficiary.remoteTransmission ?? true,
    beneficiaryContext: beneficiary,
    attachment,
  };
};

const validationBeneficiarySchema = Yup.object().shape({
  nni: Yup.string().when(['attachment'], ([attachment], schema) => {
    if (
      attachment === BeneficiaryType.PARTNER ||
      attachment === BeneficiaryType.SUBSCRIBER
    ) {
      return schema;
    }
    return yupNni.required(messageFieldRequired);
  }),
  cpamId: Yup.string().when(['attachment'], ([attachment], schema) => {
    if (
      attachment === BeneficiaryType.PARTNER ||
      attachment === BeneficiaryType.SUBSCRIBER
    ) {
      return schema;
    }
    return yupCpamId.required(messageFieldRequired);
  }),
  birthOrder: yupBirthOrder.required(messageFieldRequired),
  remoteTransmission: Yup.boolean().required(messageFieldRequired),
  attachment: Yup.string()
    .nullable()
    .when(['canBeAttached'], ([canBeAttached], schema) => {
      if (canBeAttached) {
        return schema.required(messageFieldRequired);
      }
      return schema;
    }),
});

const validationSchema = Yup.object().shape({
  beneficiaries: Yup.array().of(validationBeneficiarySchema),
});

interface DialogCPAMProps {
  neededDocuments: NeededSupportingDocument;
  onClose: () => void;
  projectId: string;
  open: boolean;
  project: Project;
}

type DialogCPAMContentProps = DialogCPAMProps & {
  previewDocument?: SupportingDocument;
  setPreviewDocument: Dispatch<SetStateAction<SupportingDocument | undefined>>;
};

const valuesToRequest = (
  values: BeneficiaryValues,
  subscriber: BeneficiaryValues,
  partner?: BeneficiaryValues,
): UpdateBeneficiaryRequest => {
  const cpamValues =
    values.attachment === BeneficiaryType.SUBSCRIBER
      ? subscriber
      : values.attachment === BeneficiaryType.PARTNER
        ? partner
        : values;
  return {
    remoteTransmission: values.remoteTransmission,
    birthOrder: values.birthOrder,
    nni: cpamValues?.nni,
    cpamId: cpamValues?.cpamId,
    id: values.beneficiaryContext.id ?? '',
  };
};

const DialogCPAMContent: FC<DialogCPAMContentProps> = ({
  previewDocument,
  setPreviewDocument,
  projectId,
  neededDocuments,
  onClose,
  project,
}) => {
  const { addSnackbar } = useAppContext();
  const { files, onDelete, onDrop, actionPending } =
    useUploadSupportingDocuments({ neededDocuments, projectId });

  const formikRef = useRef<FormikProps<CPAMFormikValues>>(null);

  const { subscriber, partner, children } = useMemo(
    () => ({
      subscriber: project.subscriber,
      ...getBeneficiariesLinkedToSubscription(project),
    }),
    [project],
  );

  const startDate = project.subscriptions![0]!.dateStart;
  const initialValues = useMemo(
    () => getInitialValues(subscriber, partner, children, startDate),
    [partner, children, subscriber, startDate],
  );
  const queryClient = useQueryClient();
  const mutation = mutations.useUpdateBeneficiaries({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['projects', projectId, 'step', 'contract-validation'],
      });

      onClose();
    },
    onError: error => {
      handleMutationError(error, {
        onBadRequest,
        handleError: () =>
          addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.')),
      });
    },
  });

  const onBadRequest = (errorDetails: UpdateBeneficiariesRequestErrors) => {
    iterateListErrors(errorDetails, 'beneficiaries', ([key, value]) =>
      setFormikError(formikRef, key, value),
    );
  };

  const handleSubmit = (values: CPAMFormikValues) => {
    const subscriber = values.beneficiaries.find(
      _ => _.beneficiaryContext.type === BeneficiaryType.SUBSCRIBER,
    )!;
    const partner = values.beneficiaries.find(
      _ => _.beneficiaryContext.type === BeneficiaryType.PARTNER,
    );
    const beneficiaries: UpdateBeneficiaryRequest[] = values.beneficiaries.map(
      _ => valuesToRequest(_, subscriber, partner),
    );
    mutation.mutate({
      projectId,
      payload: {
        beneficiaries,
      },
    });
  };

  return (
    <>
      <DialogTitle>
        <Typography variant="h2">
          Données de Sécurité Sociale
        </Typography>

        <ButtonClose
          disabled={actionPending}
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        />
      </DialogTitle>

      <DialogContent sx={{ p: 2 }}>
        <Formik<CPAMFormikValues>
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {formikProps => (
            <Form
              style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            >
              <Grid container columnSpacing={4} sx={{ height: '100%' }}>
                {previewDocument && (
                  <Grid
                    item
                    xs={5}
                    sx={{
                      height: '100%',
                    }}
                  >
                    <Box sx={{ width: '100%', height: '100%', pl: 1 }}>
                      <PreviewDocument
                        document={previewDocument}
                        onClose={() => setPreviewDocument(undefined)}
                      />
                    </Box>
                  </Grid>
                )}
                <Grid
                  item
                  xs={previewDocument ? 7 : 12}
                  sx={{ height: '100%', overflow: 'auto', pr: 1 }}
                >
                  <Box sx={{ height: '100%', pl: previewDocument ? 0 : 1 }}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      Veuillez importer le ou les attestations de Sécurité
                      sociale, en cours de validité, de chaque ouvrant droit à
                      enregistrer pour la souscription :
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={files.length > 0 ? 6 : 12}>
                          <DropzoneDocument
                            label={
                              <>
                                ou glissez et déposez{' '}
                                <strong>
                                  les attestations de Sécurité sociale
                                </strong>{' '}
                                ici.
                              </>
                            }
                            acceptedTypes={DEFAULT_FILE_ACCEPT}
                            maxSize={DEFAULT_FILE_MAX_SIZE}
                            maxFiles={neededDocuments.max}
                            nbFiles={files.length}
                            onDrop={onDrop}
                          />
                        </Grid>
                        {files.length > 0 && (
                          <Grid item xs={6}>
                            <Stack direction="column" gap={1}>
                              {files.map((_, index) => (
                                <Grid key={index} item xs={12}>
                                  <CardSupportingDocuments
                                    projectId={projectId}
                                    file={_}
                                    type={neededDocuments.type}
                                    onDelete={onDelete(_)}
                                    onPreview={setPreviewDocument}
                                    viewing={
                                      _.id ? previewDocument?.id === _.id : false
                                    }
                                  />
                                </Grid>
                              ))}
                            </Stack>
                          </Grid>
                        )}
                      </Grid>
                      {formikProps.values.beneficiaries.map((_, index) => (
                        <FormCPAMBeneficiary
                          key={_.beneficiaryContext.id}
                          name={`beneficiaries[${index}]`}
                          formikProps={formikProps}
                          values={_}
                          partnerAttachable={!!partner}
                        />
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions
        sx={{
          p: 2,
        }}
      >
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              variant="text"
              color="default"
              onClick={onClose}
              disabled={actionPending || mutation.isPending}
              sx={{
                px: 4,
              }}
            >
              Annuler
            </Button>
          </Grid>
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              type="submit"
              disabled={actionPending || mutation.isPending}
              onClick={() => formikRef.current?.submitForm()}
              sx={{
                px: 4,
              }}
            >
              {mutation.isPending ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                'Valider'
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

const DialogCPAM: React.FC<DialogCPAMProps> = props => {
  const { open } = props;
  const [previewDocument, setPreviewDocument] = useState<
    SupportingDocument | undefined
  >();
  useEffect(() => {
    if (!open) {
      setPreviewDocument(undefined);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth={previewDocument ? 'xl' : 'md'}
      fullWidth
      sx={{
        height: previewDocument ? '100%' : undefined,
      }}
      PaperProps={{ sx: { height: previewDocument ? '100%' : undefined } }}
    >
      <DialogCPAMContent
        {...props}
        previewDocument={previewDocument}
        setPreviewDocument={setPreviewDocument}
      />
    </Dialog>
  );
};

export default DialogCPAM;
