import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { appName } from 'App';

import Card from '@mui/material/Card';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { useAppContext } from 'components/Context/AppContext';
import CardDocumentDownload from 'content/document/CardDocumentDownload';
import ProjectTimeline from 'content/project/ProjectTimeline';
import CardSubscription from 'content/subscription/CardSubscription';
import SubscriptionSummary from 'content/subscription/SubscriptionSummary';

import {
  ContractualDocument,
  ContractualDocumentType,
  documentContractualOrderByType,
  documentContractualTypeData,
} from 'models/oav/ContractualDocument.models';
import { Subscription } from 'models/oav/Subscription.models';
import { SignatureStatus } from 'models/oav/CommercialProposition.models';
import { commercialPropositionQueries } from 'api/oav/CommercialPropositionQuery.api';
import { projectQueries } from 'api/oav/ProjectQuery.api';
import { ProjectStatus } from 'models/oav/ProjectStatus.models';
import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';

import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { useAccessToken } from 'utils/api/api';
import { alpha, useTheme } from '@mui/material/styles';
import { Alert } from '@mui/material';

const ProjectPage: React.FC = () => {
  document.title = `Projet - ${appName}`;

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  if (!id) return <></>;

  const theme = useTheme();
  const { addSnackbar } = useAppContext();

  const queryClient = useQueryClient();
  const projectQuery = useQuery(projectQueries.getById(id));
  const proposalQuery = useQuery(commercialPropositionQueries.get(id));
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(id));

  const getSubscriptionFromOfferCode = (code: string) => {
    const subscriptions = projectQuery.data?.subscriptions;
    if (!subscriptions) return;

    const sub = subscriptions.find(_ => _.offerCode === code);
    if (sub) return sub;
    else if (subscriptions.length > 0) return subscriptions[0];
  };

  const [subscriptionCurrent, setSubscriptionCurrent] = useState<
    Subscription | undefined
  >(getSubscriptionFromOfferCode(searchParams.get('offre') as string));

  useEffect(() => {
    setSubscriptionCurrent(
      getSubscriptionFromOfferCode(searchParams.get('offre') as string),
    );
  }, [projectQuery?.data]);

  useEffect(() => {
    if (!subscriptionCurrent?.offerCode) return;
    setSearchParams(prev => ({
      ...prev,
      offre: subscriptionCurrent.offerCode,
    }));
  }, [subscriptionCurrent?.offerCode]);

  const solutionQuery = useQuery({
    ...subscriptionQueries.getByIdSolution(id, subscriptionCurrent?.id || ''),
    enabled: !!subscriptionCurrent?.id,
  });

  const downloadFromUrl = (url: string, filename: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    setTimeout(() => link.remove(), 500);
  };

  const token = useAccessToken();
  const proposalQueryOptions = commercialPropositionQueries.get(id);
  const documentQueryOptions = (id: string, documentId: string) =>
    commercialPropositionQueries.getContractualDocument(token, id, documentId);

  const downloadDocumentContractual = (
    document: ContractualDocument,
    filename?: string,
  ) => {
    queryClient
      .fetchQuery(documentQueryOptions(id, document.id))
      .then(doc =>
        downloadFromUrl(doc.downloadUrl!, filename || document.filename),
      )
      .catch(() => {
        addSnackbar(
          getDefaultErrorSnackBar(
            'Une erreur est survenue durant le téléchargement.',
          ),
        );
      });
  };

  const downloadDocumentProposal = () => {
    queryClient
      .fetchQuery(proposalQueryOptions)
      .then(doc => downloadFromUrl(doc.downloadUrl!, doc.filename))
      .catch(() => {
        addSnackbar(
          getDefaultErrorSnackBar(
            'Une erreur est survenue durant le téléchargement.',
          ),
        );
      });
  };

  if (!projectQuery.data || !proposalQuery.data || !offersMapQuery.data) {
    return (
      <Grid item xs={12}>
        <Skeleton variant="rectangular" animation="wave" height="200px" />
      </Grid>
    );
  }

  const terminationMandateToSend =
    (projectQuery.data.status == ProjectStatus.MANAGEMENT_DONE ||
      projectQuery.data.status == ProjectStatus.MANAGEMENT_FAILED) &&
    proposalQuery.data.contractualDocuments.find(
      _ =>
        _.type == ContractualDocumentType.MANDAT_CANCELATION &&
        _.signatureStatus == SignatureStatus.SIGNED,
    );

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">
          Projet{' '}
          <pre style={{ display: 'inline-flex' }}>
            {proposalQuery.data.code}
          </pre>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Card
          sx={{
            p: 1,
          }}
        >
          <ProjectTimeline
            project={projectQuery.data}
            proposal={proposalQuery.data}
            nbEvents={1}
          />
        </Card>
      </Grid>
      {subscriptionCurrent && (
        <Grid item xs={12}>
          <CardSubscription
            project={projectQuery.data}
            subscription={subscriptionCurrent}
            offer={offersMapQuery.data[subscriptionCurrent.offerCode]}
          >
            <Grid container spacing={2}>
              {terminationMandateToSend && (
                <Grid item xs={12}>
                  <Alert
                    icon={false}
                    variant="outlined"
                    severity="warning"
                    sx={{
                      border: 2,
                      borderColor: theme.palette.warning.main,
                      backgroundColor: alpha(theme.palette.warning.main, 0.1),
                    }}
                  >
                    <Typography variant="body1">
                      {
                        documentContractualTypeData[
                          ContractualDocumentType.MANDAT_CANCELATION
                        ].label
                      }{' '}
                      {
                        documentContractualTypeData[
                          ContractualDocumentType.MANDAT_CANCELATION
                        ].icon
                      }
                    </Typography>
                    <Typography variant="body2">
                      Veuillez transmettre votre mandat de résiliation à
                      l&#39;assureur actuel de votre client via{' '}
                      <Link
                        href="https://jeresiliemoncontrat.com"
                        target="_blank"
                      >
                        jeresiliemoncontrat.com
                      </Link>
                      .
                    </Typography>
                  </Alert>
                </Grid>
              )}
              <Grid item sm={8} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography fontWeight="bold">Documents</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        md={6}
                        xs={12}
                        sx={{
                          height: '100%',
                        }}
                      >
                        <CardDocumentDownload
                          type={proposalQuery.data.mimeType}
                          name={`🗂️ Dossier de souscription${proposalQuery.data.signatureStatus === SignatureStatus.SIGNED ? ' (signé)' : ''}`}
                          onClick={() => downloadDocumentProposal()}
                        />
                        <FormHelperText>
                          Ce document regroupe tous les documents contractuels
                          du projet.
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {proposalQuery.data.contractualDocuments
                        .sort(documentContractualOrderByType)
                        .map(_ => {
                          const data = documentContractualTypeData[_.type];
                          return (
                            <Grid
                              key={_.id}
                              item
                              md={6}
                              xs={12}
                              sx={{
                                height: '100%',
                              }}
                            >
                              <CardDocumentDownload
                                type={_.mimeType}
                                name={`${data.icon ? data.icon + ' ' : ''}${data.label}${_.signatureStatus == SignatureStatus.SIGNED ? ' (signé)' : ''}`}
                                onClick={() =>
                                  downloadDocumentContractual(_, data.label)
                                }
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={4} xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SubscriptionSummary
                      project={projectQuery.data}
                      subscription={subscriptionCurrent}
                      offer={
                        offersMapQuery.data[subscriptionCurrent?.offerCode]
                      }
                      solution={solutionQuery.data}
                      showContract
                      showDate
                      showBeneficiaries
                      showSolution
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardSubscription>
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectPage;
