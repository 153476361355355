import { SignatureStatus } from 'models/oav/CommercialProposition.models';

export type ContractualDocument = {
  id: string;
  filename: string;
  mimeType: string;
  type: ContractualDocumentType;
  signatureStatus: SignatureStatus;
};

export type ContractualDocumentDownload = ContractualDocument & {
  downloadUrl: string;
};

export enum ContractualDocumentType {
  FIRST_PAGE = 'FIRST_PAGE',
  CUSTOM_STUDY = 'CUSTOM_STUDY',
  PROPOSITION_INSURANCE = 'PROPOSITION_INSURANCE',
  WARRANTIES_TABLE = 'WARRANTIES_TABLE',
  OPTIONAL_WARRANTY = 'OPTIONAL_WARRANTY',
  SUBSCRIPTION_FORM = 'SUBSCRIPTION_FORM',
  MANDAT_SEPA = 'MANDAT_SEPA',
  MANDAT_CANCELATION = 'MANDAT_CANCELATION',
  MEMBERSHIP_CERTIFICATE = 'MEMBERSHIP_CERTIFICATE',
}

export type DocumentContractualTypeData = {
  label: string;
  icon?: string;
};

export const documentContractualTypeData: Record<ContractualDocumentType, DocumentContractualTypeData> = {
  [ContractualDocumentType.FIRST_PAGE]: {
    label: 'Page de garde',
    icon: "📰",
  },
  [ContractualDocumentType.CUSTOM_STUDY]: {
    label: 'Etude personnalisée',
    icon: "🧪",
  },
  [ContractualDocumentType.PROPOSITION_INSURANCE]: {
    label: 'Proposition',
    icon: "🎯",
  },
  [ContractualDocumentType.WARRANTIES_TABLE]: {
    label: 'Tableau de garanties',
    icon: "🩹",
  },
  [ContractualDocumentType.OPTIONAL_WARRANTY]: {
    label: 'Tableau de garanties optionnelles',
    icon: "🩹",
  },
  [ContractualDocumentType.SUBSCRIPTION_FORM]: {
    label: 'Bulletion d\'adhésion',
    icon: "✍️",
  },
  [ContractualDocumentType.MANDAT_SEPA]: {
    label: 'Mandat SEPA',
    icon: "🏦",
  },
  [ContractualDocumentType.MANDAT_CANCELATION]: {
    label: 'Mandat de résiliation',
    icon: "🛑",
  },
  [ContractualDocumentType.MEMBERSHIP_CERTIFICATE]: {
    label: 'Certificat d\'adhésion',
    icon: "🎓",
  }
};

export const getDocumentContractualTypeValue = (type: ContractualDocumentType) => {
  switch (type) {
    case ContractualDocumentType.MEMBERSHIP_CERTIFICATE: return 100;
    case ContractualDocumentType.SUBSCRIPTION_FORM: return 90;
    case ContractualDocumentType.MANDAT_SEPA: return 80;
    case ContractualDocumentType.MANDAT_CANCELATION: return 70;
    case ContractualDocumentType.PROPOSITION_INSURANCE: return 60;
    case ContractualDocumentType.CUSTOM_STUDY: return 50;
    case ContractualDocumentType.WARRANTIES_TABLE: return 40;
    case ContractualDocumentType.OPTIONAL_WARRANTY: return 30;
    default: return 0;
  }
}

export const documentContractualOrderByType = (a: ContractualDocument, b: ContractualDocument) => {

  const aTypeValue = getDocumentContractualTypeValue(a.type);
  const bTypeValue = getDocumentContractualTypeValue(b.type);

  if (aTypeValue > bTypeValue)
    return -1;

  if (aTypeValue < bTypeValue)
    return 1;

  return 0;
}
