export enum TarificationPeriod {
  MONTHLY = 'MONTHLY',
  ANNUALLY = 'ANNUALLY',
}

export const enumTarificationPeriod = [
  {
    value: TarificationPeriod.MONTHLY,
    label: 'par mois',
  },
  {
    value: TarificationPeriod.ANNUALLY,
    label: 'par an',
  },
];

export type Solution = {
  id: string;
  order?: number;
  base: SolutionItem;
  reinforcements?: SolutionItem[];
  options?: SolutionItem[];
  pricing?: SolutionPricing;
};

export type SolutionItem = {
  offer: SolutionOffer;
  formula?: SolutionFormula;
  pricing?: SolutionPricing;
};

export type SolutionOffer = {
  code: string;
  label: string;
  description?: string;
};

export type SolutionFormula = {
  code: string;
  label: string;
  description?: string;
  color?: string;
};

export type SolutionPricing = {
  perMonth: number;
  perYear: number;
};

export type SolutionRequest = {
  subscriptionId: string;
  order?: number;
  base: SolutionItemRequest;
  reinforcements?: SolutionItemRequest[];
  options?: SolutionItemRequest[];
};

export type SolutionItemRequest = {
  offerCode: string;
  formulaCode: string;
};
