import { FC } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Offer } from 'models/referentiels/Offer.model';
import { ChipOfferRisk } from 'components/Chip/ChipOfferRisk';

type OfferDescriptionProps = {
  offer: Offer;
  hideDescription?: boolean;
};

export const OfferDescription: FC<OfferDescriptionProps> = props => {
  return (
    <Stack gap={1} useFlexGap direction="column">
      <Stack
        direction={{
          sm: 'row',
          xs: 'column-reverse',
        }}
        gap={1}
      >
        <Typography variant="body1">
          <b>{props.offer.label}</b>
        </Typography>

        <Stack direction="row" gap={1} flexWrap="wrap">
          {props.offer.risks?.map(risk => (
            <ChipOfferRisk key={risk.code} risk={risk} />
          ))}
        </Stack>
      </Stack>

      {props.hideDescription !== true && (
        <Typography variant="body2">{props.offer.description}</Typography>
      )}
    </Stack>
  );
};
