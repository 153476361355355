import {
  Tarification,
  TrfFormula,
  TrfPricing,
} from 'models/referentiels/Tarification.models';
import { Formula } from 'models/referentiels/Formula.model';
import { Offer } from 'models/referentiels/Offer.model';

export type TarificationMap = Record<string, Tarification | undefined>;
export type TarificationFormulaMap = Record<string, TrfFormula | undefined>;
export type OfferFormulaTarificationMap = Record<
  string,
  Record<string, { formula: Formula; pricing?: TrfPricing } | undefined>
>;

export const tarificationToMap = (
  tarification?: Tarification,
): Record<string, Tarification | undefined> => {
  const result: Record<string, Tarification | undefined> = {};
  if (tarification) {
    result[tarification.offer.code] = tarification;
    tarification.options?.forEach(o => {
      result[o.offer.code] = o;
    });
    tarification.reinforcements?.forEach(r => {
      result[r.offer.code] = r;
    });
  }
  return result;
};

export const toTarificationFormulaMap = (
  tarification?: Tarification,
): TarificationFormulaMap => {
  if (!tarification) {
    return {};
  }
  const formulas = tarification.formula
    ? [tarification.formula]
    : (tarification.formulas ?? []);
  return formulas.reduce<TarificationFormulaMap>((map, tf) => {
    map[tf.code] = tf;
    return map;
  }, {});
};

export const toFormulaTarificationMap = (
  formulas: Formula[],
  trfFormulaMap?: TarificationFormulaMap,
) => {
  return formulas.reduce<
    Record<string, { formula: Formula; pricing?: TrfPricing }>
  >((map, f) => {
    map[f.code] = {
      formula: f,
      pricing: trfFormulaMap?.[f.code]?.tarification?.pricing,
    };
    return map;
  }, {});
};

export const toOffersFormulasTarificationMap = (
  offer?: Offer,
  tarificationMap?: TarificationMap,
) => {
  const res: OfferFormulaTarificationMap = {};

  if (!offer) {
    return res;
  }

  res[offer.code] = toFormulaTarificationMap(
    offer.formulas,
    toTarificationFormulaMap(tarificationMap?.[offer.code]),
  );
  offer.options?.forEach(o => {
    res[o.code] = toFormulaTarificationMap(
      o.formulas,
      toTarificationFormulaMap(tarificationMap?.[o.code]),
    );
  });
  offer.reinforcements?.forEach(r => {
    res[r.code] = toFormulaTarificationMap(
      r.formulas,
      toTarificationFormulaMap(tarificationMap?.[r.code]),
    );
  });

  return res;
};
