import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import { Offer } from 'models/referentiels/Offer.model';

export type CardOfferLinkProps = {
  offer: Offer;
};

const CardOfferLink = (props: CardOfferLinkProps) => {
  const theme = useTheme();

  // TODO : Add Link / onClick

  return (
    <Card
      sx={{
        borderRadius: 2,
        px: 1,
        py: 0,
        background: 'none',
        cursor: 'pointer',
        ':hover': {
          background: theme.palette.primary.main + '20',
        },
      }}
    >
      <Typography component="div" variant="caption">
        {props.offer.label}
      </Typography>
    </Card>
  );
};

export default CardOfferLink;
