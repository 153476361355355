import { RestError } from 'errors/RestError';
import {
  BankDetailsRequest,
  Subscription,
  SubscriptionRequest,
} from 'models/oav/Subscription.models';
import { Tarification } from 'models/referentiels/Tarification.models';
import { formatDate } from 'utils/api/api';
import { mapBeneficiary } from 'api/oav/Beneficiary.api';
import { Solution } from 'models/oav/Solution.models';
import { getRequest } from 'utils/queries/queries.utils';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/project`;

export const mapSubscription = (subscription: any): Subscription => ({
  ...subscription,
  dateStart: subscription.dateStart && new Date(subscription.dateStart),
  beneficiaries: subscription.beneficiaries.map(mapBeneficiary),
});

export const updateSubscription = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
  payload: SubscriptionRequest,
): Promise<Subscription> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}`;
  const body = {
    ...payload,
    dateStart: formatDate(payload.dateStart),
  };
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      body,
      await response.json(),
    );

  return await response.json();
};

// Get tarification
export const fetchSubscriptionTarification = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
): Promise<Tarification> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/tarification`;

  return await getRequest(url, accessToken);
};

// Get solution
export const fetchSubscriptionSolution = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
): Promise<Solution> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/solution`;

  return await getRequest(url, accessToken);
};

// Get solutions
export const fetchSubscriptionSolutions = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
): Promise<Solution[]> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/solutions`;

  return await getRequest(url, accessToken);
};

// Update bank details
export const updateBankDetails = async (
  accessToken: string,
  projectId: string,
  subscriptionId: string,
  payload: BankDetailsRequest,
): Promise<Subscription> => {
  const url = `${baseUrl}/${projectId}/subscription/${subscriptionId}/bankDetails`;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (response.status / 100 >= 4)
    throw new RestError(
      response.status,
      url,
      'PUT',
      payload,
      await response.json(),
    );

  return await response.json();
};
