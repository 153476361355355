import { Formula } from 'models/referentiels/Formula.model';
import { Risk } from 'models/referentiels/Risk.model';
import { Segment } from 'models/referentiels/Segment';
import { OfferNeed } from 'models/offerNeed.models';
import { SupportingDocumentType } from 'models/referentiels/Document.models';

interface Offer {
  id: string;
  techDateCreation: Date;
  techDateModification: Date;
  code: string;
  label: string;
  description: string;
  details?: string;
  risks?: Risk[];
  segments?: Segment[];
  formulas: Formula[];
  needs?: OfferNeed[];
  supportingDocumentTypes?: SupportingDocumentType[];
  options?: Offer[];
  reinforcements?: Offer[];
  type: OfferType;
}

export enum OfferType {
  BASE = 'BASE',
  OPTION = 'OPTION',
  REINFORCEMENT = 'REINFORCEMENT',
}

export type OffersMap = { [key: string]: Offer };

/**
 * Context of Offer eligibility.
 */
export interface OfferEligibility {
  date: Date;
  postalCode: string;
  subscriber: OfferEligibilityBeneficiary;
  partner?: OfferEligibilityBeneficiary;
  children?: OfferEligibilityBeneficiary[];
}

/**
 * Context beneficiary of Offer eligibility.
 */
export interface OfferEligibilityBeneficiary {
  regime: string;
  birthDate: Date;
}

export type { Offer };
