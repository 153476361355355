import { Icon } from '.';
import { IconProps } from './settings';

export const HomeCircleIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
          fill={props.bgColor}
        />
        <path
          d="M12.6 30V22.9412H17.4V30H23.4V20.5882H27L15 10L3 20.5882H6.6V30H12.6Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
};
