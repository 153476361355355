import React, { useRef } from 'react';
import { queryClient } from 'config';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';

import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useAppContext } from 'components/Context/AppContext';
import { CloseIcon } from 'components/Icon/CloseIcon';
import TextField from 'components/TextField/TextField';

import { Project, ProjectSignatureRequest } from 'models/oav/Project.models';
import { requestDigitalSignature } from 'api/oav/Project.api';

import { TransitionSlideUP } from 'utils/animations/transitions';
import { useAccessToken } from 'utils/api/api';
import {
  getDefaultErrorSnackBar,
  getDefaultSuccessSnackBar,
} from 'utils/snackbars/Snackbars';
import { messageFieldRequired } from 'utils/validation/message';

interface DialogSignatureDigitalProps {
  project: Project;
  open: boolean;
  onClose: () => void;
}

interface FormValues {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required(messageFieldRequired).email('Email non valide'),
});

const DialogSignatureDigital: React.FC<DialogSignatureDigitalProps> = props => {
  const theme = useTheme();
  const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

  const accessToken = useAccessToken();
  const { addSnackbar } = useAppContext();

  const refFormik = useRef<FormikProps<FormValues>>(null);
  const initialValues: FormValues = {
    email: props.project.subscriber.person.email ?? '',
  };

  const mutation = useMutation({
    mutationFn: (params: {
      projectId: string;
      payload: ProjectSignatureRequest;
    }) =>
      requestDigitalSignature(params.projectId, params.payload, accessToken),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['projects', props.project.id],
        exact: true,
      });
      addSnackbar(getDefaultSuccessSnackBar('Signature électronique envoyée'));
      props.onClose();
    },
    onError: (error: Error) => {
      addSnackbar(
        getDefaultErrorSnackBar(
          "L'envoi de la signature électronique a échoué",
        ),
      );
      props.onClose();
    },
  });

  const handleSubmit = (values: FormValues) => {
    mutation.mutate({
      projectId: props.project.id,
      payload: {
        subscriberMail: values.email,
      },
    });
  };

  return (
    <Dialog
      open={props.open}
      fullWidth
      fullScreen={!screenSizeUpSM}
      maxWidth="md"
      onClose={(event, reason) => {
        if (mutation.isPending) {
          return;
        }
        if (reason && reason === 'backdropClick') return;
        props.onClose();
      }}
      TransitionComponent={TransitionSlideUP}
    >
      <DialogTitle>
        <Typography variant="h2">Signature électronique</Typography>
        <IconButton
          aria-label="close"
          onClick={() => props.onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            ':hover': {
              backgroundColor: 'rgba(250, 250, 250, 0.04)',
            },
          }}
        >
          <CloseIcon color={theme.palette.text.primary} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          innerRef={refFormik}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleBlur, handleChange }) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Alert
                    icon={false}
                    variant="outlined"
                    severity="warning"
                    sx={{
                      border: 2,
                      borderColor: theme.palette.warning.main,
                      backgroundColor: alpha(theme.palette.warning.main, 0.1),
                    }}
                  >
                    <Typography variant="body2">
                      Vous vous apprêtez à envoyer un lien de signature
                      électronique à votre prospect.
                    </Typography>
                    <Typography sx={{ pt: 0.5 }}>
                      Une fois signé, ce projet ne pourra plus être modifié et
                      la mise en gestion se fera automatiquement.
                    </Typography>
                  </Alert>
                </Grid>

                <Grid item xs={12}>
                  <Alert
                    icon={false}
                    variant="outlined"
                    severity="error"
                    sx={{
                      border: 2,
                      borderColor: theme.palette.error.main,
                      backgroundColor: alpha(theme.palette.error.main, 0.1),
                    }}
                  >
                    <Typography fontWeight="bold" sx={{ mb: 1 }}>
                      Vente à distance 📞
                    </Typography>
                    <Typography variant="body2">
                      En cas de vente effectuée exclusivement <b>à distance</b>{' '}
                      et faisant suite à un <b>démarchage commercial</b> de
                      votre part. veuillez respecter un <b>délai de 24h</b>{' '}
                      entre l'envoi de votre proposition d'assurance et l'envoi
                      du lien de signature électronique à votre prospect.
                    </Typography>
                  </Alert>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    name="email"
                    label="Email du prospect"
                    value={values.email}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{ minLength: 1, maxLength: 100 }}
                  />
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </DialogContent>

      <DialogActions
        sx={{
          px: 3,
          pb: 2,
          pt: 0,
        }}
      >
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              variant="text"
              color="default"
              disabled={mutation.isPending}
              onClick={() => props.onClose()}
              sx={{ px: 4 }}
            >
              Annuler
            </Button>
          </Grid>

          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              onClick={() => refFormik.current?.submitForm()}
              sx={{ px: 4 }}
            >
              {mutation.isPending ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                'Envoyer la signature'
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSignatureDigital;
