import React, { FC, useMemo } from 'react';

import Grid from '@mui/material/Grid';

import { Offer } from 'models/referentiels/Offer.model';
import CardFormula from 'content/formula/CardFormula';
import { Formula } from 'models/referentiels/Formula.model';
import {
  Tarification,
  TrfPricing,
} from 'models/referentiels/Tarification.models';
import FormHelperText from '@mui/material/FormHelperText';
import { CardProps } from '@mui/material/Card';

export type CardFormulaListProps = {
  offer: Offer;
  baseFormulas?: Formula[];
  labelOnLeftColumn?: boolean;
  showWarranties?: boolean;
  tarification?: Tarification;
  selectedFormula?: string;
  onFormulaSelected?: (formula: Formula, selected: boolean) => void;
  recommendedFormulaCode?: string;
  errorHelperText?: string | string[] | undefined;
  selectableFormulas?: string[];
  cardFormulaProps?: {
    sx?: CardProps['sx'];
  };
};

export const CardFormulaList: FC<CardFormulaListProps> = ({
  offer,
  baseFormulas,
  showWarranties,
  labelOnLeftColumn,
  tarification,
  onFormulaSelected,
  selectedFormula,
  recommendedFormulaCode,
  errorHelperText,
  selectableFormulas,
  cardFormulaProps,
}) => {
  const formulas = useMemo(() => {
    if ((baseFormulas?.length ?? 0 > 0) && offer.formulas.length > 0) {
      const linkedToBaseFormulas =
        (offer.formulas[0].formulasComboCode?.length ?? 0) > 0;
      if (linkedToBaseFormulas) {
        const formulasComboMap = offer.formulas.reduce<
          Record<string, Formula | undefined>
        >((previousValue, currentValue) => {
          const baseFormulasCode = currentValue.formulasComboCode?.[0];
          if (baseFormulasCode) {
            previousValue[baseFormulasCode] = currentValue;
          }
          return previousValue;
        }, {});

        return baseFormulas!.map(_ => formulasComboMap[_.code] ?? null);
      }
    }

    return offer.formulas;
  }, [offer, baseFormulas]);

  const gridItemSize =
    12 / (formulas.length + (labelOnLeftColumn && showWarranties ? 2 : 0));

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      {formulas.map((f, index) => {
        let handleFormulaSelected = undefined;
        let pricing: TrfPricing | undefined = undefined;
        let selected: boolean = false;
        let recommended = false;
        if (f) {
          if (!selectableFormulas || selectableFormulas.includes(f.code)) {
            handleFormulaSelected = onFormulaSelected
              ? () => onFormulaSelected(f, !selected)
              : undefined;
          }

          if (f) {
            pricing =
              tarification?.formula?.code === f.code
                ? tarification?.formula?.tarification?.pricing
                : tarification?.formulas?.find(_ => _.code === f.code)
                    ?.tarification?.pricing;
          }
          selected = selectedFormula === f.code;
          recommended = f.code === recommendedFormulaCode;
        }
        return (
          <Grid
            key={index}
            item
            xs={12}
            md={gridItemSize}
            sx={{
              transition: 'all 200ms ease-in-out',
            }}
          >
            {f ? (
              <CardFormula
                formula={f}
                pricing={pricing}
                sx={{ width: '100%', ...cardFormulaProps?.sx }}
                onClick={handleFormulaSelected}
                selected={selected}
                recommended={recommended}
              />
            ) : null}
          </Grid>
        );
      })}
      {errorHelperText && (
        <Grid item xs={12}>
          <FormHelperText error>{errorHelperText}</FormHelperText>
        </Grid>
      )}
    </Grid>
  );
};
