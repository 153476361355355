import { Solution, SolutionRequest } from 'models/oav/Solution.models';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from 'utils/queries/queries.utils';

const baseUrl = `${process.env.REACT_APP_API_URI_BASE}/oav/solution`;

export const getSolutionById = async (
  accessToken: string,
  id: string,
): Promise<Solution> => {
  const url: string = `${baseUrl}/${id}`;

  return await getRequest(url, accessToken);
};

export const createSolution = async (
  accessToken: string,
  payload: SolutionRequest,
): Promise<Solution> => {
  const url: string = baseUrl;

  return await postRequest(url, payload, accessToken);
};

export const updateSolution = async (
  accessToken: string,
  id: string,
  payload: SolutionRequest,
): Promise<Solution> => {
  const url: string = `${baseUrl}/${id}`;

  return await putRequest(url, payload, accessToken);
};

export const deleteSolution = async (
  accessToken: string,
  id: string,
): Promise<void> => {
  const url: string = `${baseUrl}/${id}`;

  return await deleteRequest(url, accessToken);
};
