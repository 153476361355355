import { darken, useTheme } from '@mui/material/styles';

import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

import { OfferDescription } from 'content/offer/OfferDescription';
import FormSubscription from 'content/subscription/FormSubscription';

import { Subscription } from 'models/oav/Subscription.models';
import { Project } from 'models/oav/Project.models';
import { Offer } from 'models/referentiels/Offer.model';

export type CardSubscriptionProps = {
  project: Project;
  subscription: Subscription;
  offer: Offer;
  divider?: boolean;
  showForm?: boolean;
  onUpdate?: (subscription: Subscription) => void;
  children?: JSX.Element;
};

const CardSubscription = (props: CardSubscriptionProps) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        p: 2,
        backgroundColor: darken(theme.palette.background.default, 0.1),
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container gap={2}>
            <Grid
              item
              lg
              xs={12}
              sx={{
                height: '100%',
              }}
            >
              <OfferDescription offer={props.offer} />
            </Grid>

            {props.showForm && (
              <Grid item lg="auto" xs={12}>
                <FormSubscription
                  disabled={!props.onUpdate}
                  project={props.project}
                  subscription={props.subscription}
                  onChange={props.onUpdate}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        {props.children && (
          <>
            {props.divider && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}

            <Grid item xs={12}>
              {props.children}
            </Grid>
          </>
        )}
      </Grid>
    </Card>
  );
};

export default CardSubscription;
