export enum ProjectStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  PARTS_PENDING = 'PARTS_PENDING',
  PARTS_TO_VALIDATE = 'PARTS_TO_VALIDATE',
  SIGNATURE_PENDING = 'SIGNATURE_PENDING',
  SIGNATURE_IN_PROGRESS = 'SIGNATURE_IN_PROGRESS',
  SIGNATURE_DONE = 'SIGNATURE_DONE',
  MANAGEMENT_IN_PROGRESS = 'MANAGEMENT_IN_PROGRESS',
  MANAGEMENT_FAILED = 'MANAGEMENT_FAILED',
  MANAGEMENT_DONE = 'MANAGEMENT_DONE',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
}

export type ProjectStatusData = {
  label: string;
  color: string;
  description?: string;
};

export const projectStatusData: Record<ProjectStatus, ProjectStatusData> = {
  [ProjectStatus.IN_PROGRESS]: {
    label: 'En cours de saisie',
    color: '#2155CD',
  },
  [ProjectStatus.PARTS_PENDING]: {
    label: 'Attente de pièces',
    color: '#F89136',
  },
  [ProjectStatus.PARTS_TO_VALIDATE]: {
    label: 'Pièces à valider',
    color: '#F89136',
  },
  [ProjectStatus.SIGNATURE_PENDING]: {
    label: 'À signer',
    color: '#F89136',
    description: 'Ce projet est prêt à être signé.',
  },
  [ProjectStatus.SIGNATURE_IN_PROGRESS]: {
    label: 'Attente de signature',
    color: '#F89136',
    description: 'Un lien de signature électronique a été envoyé au prospect.',
  },
  [ProjectStatus.SIGNATURE_DONE]: {
    label: 'Signé',
    color: '#52E09C',
    description: 'Ce projet est signé et sera mis en gestion automatiquement.',
  },
  [ProjectStatus.EXPIRED]: {
    label: 'Expiré',
    color: '#AFB6C8',
  },
  [ProjectStatus.CANCELED]: {
    label: 'Annulé',
    color: '#FF5D5D',
  },
  [ProjectStatus.MANAGEMENT_IN_PROGRESS]: {
    label: 'Mise en gestion en cours...',
    color: '#2155CD',
  },
  [ProjectStatus.MANAGEMENT_FAILED]: {
    label: 'Échec de mise en gestion',
    color: '#FF5D5D',
    description: 'La mise en gestion automatique de ce projet a échoué.',
  },
  [ProjectStatus.MANAGEMENT_DONE]: {
    label: 'Mis en gestion',
    color: '#52E09C',
    description: 'Ce projet a été mis en gestion automatiquement.',
  },
};

export const getProjectStatusAsEntries = (): [
  ProjectStatus,
  ProjectStatusData,
][] => {
  return Object.entries(projectStatusData) as [
    ProjectStatus,
    ProjectStatusData,
  ][];
};
