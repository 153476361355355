import { Offer } from 'models/referentiels/Offer.model';
import React from 'react';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { offerQueries } from 'api/referentiels/OfferQuery.api';
import { Grid } from '@mui/material';
import { DocumentCard } from './DocumentCard';

interface OfferDocumentsProps {
  offer: Offer;
}

export const OfferDocuments: React.FC<OfferDocumentsProps> = ({ offer }) => {
  const documentQuery = useQuery(offerQueries.getByCodeDocuments(offer.code));

  return (
    <>
      <Typography variant="h5">{offer.label}</Typography>

      <Typography variant="body2" sx={{ my: 2 }}>
        {offer.description}
      </Typography>

      <Grid container spacing={2}>
        {documentQuery.data
          ?.sort((a, b) => a.type.label.localeCompare(b.type.label))
          .map(document => <DocumentCard document={document} />)}
      </Grid>
    </>
  );
};
