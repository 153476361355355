import { useFormikContext } from 'formik';

import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from 'components/TextField/TextField';
import Typography from '@mui/material/Typography';

import { HomeCircleIcon } from 'components/Icon/HomeCircleIcon';
import { AddressFormValues } from 'models/Form.model';

export const FormProjectAddressDetails = () => {
  const theme = useTheme();

  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<AddressFormValues>();

  return (
    <Grid
      container
      spacing={{
        sm: 2,
        xs: 1,
      }}
    >
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center">
          <HomeCircleIcon
            size="large"
            color={theme.palette.background.default}
            bgColor={theme.palette.text.primary}
          />
          <Typography variant="h3">Adresse</Typography>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          value={values.addressLine1}
          id="addressLine1"
          name="addressLine1"
          label="N° et nom de voie"
          onChange={handleChange}
          error={touched.addressLine1 && !!errors.addressLine1}
          helperText={touched.addressLine1 && errors.addressLine1}
          onBlur={handleBlur}
          inputProps={{
            maxLength: 300,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="addressLine2"
          name="addressLine2"
          label="Complément d'adresse 1"
          value={values.addressLine2}
          error={touched.addressLine2 && !!errors.addressLine2}
          helperText={touched.addressLine2 && errors.addressLine2}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ maxLength: 300 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="addressLine3"
          name="addressLine3"
          label="Complément d'adresse 2"
          value={values.addressLine3}
          error={touched.addressLine3 && !!errors.addressLine3}
          helperText={touched.addressLine3 && errors.addressLine3}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ maxLength: 300 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id="postalCode"
          name="postalCode"
          label="Code postal"
          value={values.postalCode}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id="city"
          name="city"
          label="Ville"
          value={values.city}
          error={touched.city && !!errors.city}
          helperText={touched.city && errors.city}
          onChange={handleChange}
          onBlur={handleBlur}
          inputProps={{ maxLength: 255 }}
        />
      </Grid>
    </Grid>
  );
};
