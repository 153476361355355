import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';

import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useProjectContext } from 'components/Context/ProjectContext';
import TextField from 'components/TextField/TextField';

import { useAppContext } from 'components/Context/AppContext';
import { Icon } from 'components/Icon';
import { CloseIcon } from 'components/Icon/CloseIcon';
import { ErrorMessage } from 'components/Message/ErrorMessage';
import CardSubscription from 'content/subscription/CardSubscription';
import GridSubscriptionSolutions from 'content/subscription/GridSubscriptionSolutions';

import { projectQueries } from 'api/oav/ProjectQuery.api';
import { commercialPropositionMutations } from 'api/oav/CommercialPropositionQuery.api';

import { StaticDocumentTypeCodes } from 'models/referentiels/Document.models';
import { CommercialProposition } from 'models/oav/CommercialProposition.models';

import {
  getDefaultErrorSnackBar,
  getDefaultSuccessSnackBar,
} from 'utils/snackbars/Snackbars';
import { yupEmail } from 'utils/validation/yupPerson';
import { messageFieldRequired } from 'utils/validation/message';

export const validationSchema = Yup.object().shape({
  email: yupEmail.required(messageFieldRequired),
});

const estimationOfferDocumentsMap: {
  [key: string]: StaticDocumentTypeCodes[];
} = {
  '312': [
    StaticDocumentTypeCodes.DOCUMENT_INFORMATION,
    StaticDocumentTypeCodes.REGLEMENT_MUTUALISTE,
    StaticDocumentTypeCodes.CONDITIONS_GENERALES_ASSISTANCE,
    StaticDocumentTypeCodes.STATUTS_GSMC,
  ],
  '512': [
    StaticDocumentTypeCodes.DOCUMENT_INFORMATION,
    StaticDocumentTypeCodes.REGLEMENT_MUTUALISTE,
    StaticDocumentTypeCodes.CONDITIONS_GENERALES_ASSISTANCE,
    StaticDocumentTypeCodes.STATUTS_GSMC,
  ],
  '539': [
    StaticDocumentTypeCodes.DOCUMENT_INFORMATION,
    StaticDocumentTypeCodes.CONDITIONS_GENERALES,
    StaticDocumentTypeCodes.CONDITIONS_GENERALES_ASSISTANCE,
    StaticDocumentTypeCodes.STATUTS_GSMC,
    StaticDocumentTypeCodes.ANIP,
  ],
};

export const CommercialPropositionDialog = () => {
  const { id: projectId } = useParams();
  const validatedWithMail = useRef(false);

  if (!projectId) return <></>;

  const theme = useTheme();

  const projectQueryOptions = projectQueries.getById(projectId);
  const projectQuery = useQuery(projectQueryOptions);
  const offersMapQuery = useQuery(projectQueries.getByIdOffersMap(projectId));

  const { closeCommercialPropositionDialog } = useProjectContext();

  const { addSnackbar } = useAppContext();
  const queryClient = useQueryClient();

  const project = projectQuery.data;

  const subscription = project?.subscriptions?.[0];

  const offerCode = subscription?.offerCode || '';

  const offer = offersMapQuery.data?.[offerCode];

  const handleSuccess = async (data: CommercialProposition) => {
    if (validatedWithMail.current) {
      addSnackbar(
        getDefaultSuccessSnackBar('Le projet a été envoyé avec succès.'),
      );
    } else {
      window.open(data.downloadUrl, '_blank');
    }

    try {
      await queryClient.invalidateQueries({
        queryKey: projectQueryOptions.queryKey,
      });
    } catch (e) {
      // Do not throw error if the invalidate fails
      console.error(e);
    }

    closeCommercialPropositionDialog();
  };

  const handleError = () => {
    addSnackbar(
      getDefaultErrorSnackBar(
        'Une erreur est survenue durant la génération du projet.',
      ),
    );
  };

  const { isPending: isMutationPending, mutate } =
    commercialPropositionMutations.useCreate(handleSuccess, handleError);

  const title = 'Édition de projet';

  if (offersMapQuery.error || projectQuery.error) {
    return (
      <ErrorDialog onClose={closeCommercialPropositionDialog} title={title} />
    );
  }

  if (!project || !subscription || !offer) {
    return <LoadingDialog title={title} />;
  }

  const documentsToDisplay =
    estimationOfferDocumentsMap[subscription.offerCode] || [];

  const formikRef = useRef<FormikProps<{ email?: string }>>(null);

  const handleSubmitWithEmail = async () => {
    validatedWithMail.current = true;
    try {
      mutate({
        projectId: project.id,
        payload: {
          sendEmail: true,
          email: formikRef!.current!.values.email,
        },
      });
    } catch (e) {
      handleError();
    }
  };

  const handleSubmitWithoutEmail = async () => {
    validatedWithMail.current = false;
    try {
      mutate({
        projectId: project.id,
        payload: {
          sendEmail: false,
        },
      });
    } catch (e) {
      handleError();
    }
  };

  const loadingWithMail = isMutationPending && validatedWithMail.current;
  const loadingWithoutMail = isMutationPending && !loadingWithMail;

  return (
    <>
      <DialogTitle>
        <Typography variant="h2">{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={closeCommercialPropositionDialog}
          disabled={isMutationPending}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            ':hover': {
              backgroundColor: 'rgba(250, 250, 250, 0.04)',
            },
          }}
        >
          <Icon color={theme.palette.text.primary}>
            <CloseIcon />
          </Icon>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Formik
          innerRef={formikRef}
          initialValues={{
            email: project.subscriber?.person?.email ?? '',
          }}
          onSubmit={handleSubmitWithEmail}
          validationSchema={validationSchema}
          validateOnMount={true}
        >
          {({ values, handleChange, handleBlur, errors, touched }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardSubscription
                    divider
                    project={project}
                    subscription={subscription}
                    offer={offer}
                  >
                    <GridSubscriptionSolutions
                      project={project}
                      subscription={subscription}
                      offer={offer}
                    />
                  </CardSubscription>
                </Grid>

                {/* {documentsToDisplay && documentsToDisplay.length > 0 && (
                <Grid item xs={12}>
                  <Typography>
                    <b>Envoyez par mail</b> ou&nbsp;
                    <b>téléchargez</b> votre devis complet qui comprend les
                    éléments suivants :
                  </Typography>
                  <ul>
                    <li>Votre étude personnalisée</li>
                    <li>Votre proposition d&apos;assurance</li>
                    {documentsToDisplay.map((document, index) => (
                      <li key={index}>
                        {staticDocumentLabels[document]?.label}
                      </li>
                    ))}
                  </ul>
                </Grid>
              )} */}

                <Grid item xs={12}>
                  <TextField
                    variant="filled"
                    fullWidth
                    id="email"
                    name="email"
                    label="Email de votre client"
                    value={values.email}
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{ minLength: 1, maxLength: 100 }}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
        }}
      >
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              variant="text"
              color="default"
              onClick={closeCommercialPropositionDialog}
              disabled={isMutationPending}
              sx={{
                px: 4,
              }}
            >
              Annuler
            </Button>
          </Grid>

          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={handleSubmitWithoutEmail}
              disabled={loadingWithMail || loadingWithoutMail}
              sx={{
                px: 4,
              }}
            >
              {loadingWithoutMail ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                'Télécharger'
              )}
            </Button>
          </Grid>

          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              type="submit"
              color="primary"
              disabled={loadingWithMail || loadingWithoutMail}
              onClick={handleSubmitWithEmail}
              sx={{
                px: 4,
              }}
            >
              {loadingWithMail ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                'Envoyer par mail'
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

const LoadingDialog = ({ title }: { title: string }) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Skeleton
        sx={{ mt: 2 }}
        variant="rectangular"
        animation="wave"
        height="50px"
        width={'500px'}
      />
      <Skeleton
        sx={{ mt: 2 }}
        variant="rectangular"
        animation="wave"
        height="100px"
        width={'500px'}
      />
      <Skeleton
        sx={{ mt: 2 }}
        variant="rectangular"
        animation="wave"
        height="50px"
        width={'500px'}
      />
    </DialogContent>
  </>
);

const ErrorDialog = ({
  title,
  onClose,
}: {
  title: string;
  onClose: () => void;
}) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <ErrorMessage message="Une erreur est survenue" />
    </DialogContent>
    <DialogActions>
      <Button color="default" onClick={onClose}>
        Fermer
      </Button>
    </DialogActions>
  </>
);
