import { useRef } from 'react';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { useMutation } from '@tanstack/react-query';

import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useAppContext } from 'components/Context/AppContext';
import { CloseIcon } from 'components/Icon/CloseIcon';
import {
  DropzoneDocument,
  DropzoneDocumentRef,
} from 'components/Dropzone/DropzoneDocument';
import CardDocument from 'content/document/CardDocument';

import { useAccessToken } from 'utils/api/api';
import { TransitionSlideUP } from 'utils/animations/transitions';
import {
  DEFAULT_FILE_ACCEPT,
  DEFAULT_FILE_MAX_SIZE,
} from 'utils/document/dropZoneUtils';
import {
  getMimeTypeFromExtension,
  MimeType,
  mimeTypeDetails,
} from 'utils/document/documentUtils';
import { messageFieldRequired } from 'utils/validation/message';
import {
  getDefaultErrorSnackBar,
  getDefaultSuccessSnackBar,
} from 'utils/snackbars/Snackbars';

import { queryClient } from 'config';
import { Project } from 'models/oav/Project.models';
import { signProjectManually } from 'api/oav/Project.api';
import { RestError } from 'errors/RestError';
import { ApiResponse } from 'models/api.model';

export interface FormSignatureUploadValues {
  commercialProposition?: File | undefined;
  consent?: boolean;
}

export type DialogSignatureUploadProps = {
  project: Project;
  open: boolean;
  onClose: () => void;
};

const DialogSignatureUpload = (props: DialogSignatureUploadProps) => {
  const theme = useTheme();
  const { addSnackbar } = useAppContext();
  const token = useAccessToken();

  const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

  const formikRef = useRef<FormikProps<FormSignatureUploadValues>>(null);
  const dropzoneRef = useRef<DropzoneDocumentRef>();
  const handleOpenDropzone = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current!.open();
    }
  };

  const validationSchema = Yup.object().shape({
    commercialProposition: Yup.mixed().required(messageFieldRequired),
    consent: Yup.bool()
      .required(messageFieldRequired)
      .oneOf([true], 'Confirmation obligatoire'),
  });

  const mutationProjectSignatureManual = useMutation<
    Project,
    Error,
    { commercialProposition: File }
  >({
    mutationFn: (payload: { commercialProposition: File }) => {
      return signProjectManually(
        token,
        props.project.id,
        payload.commercialProposition,
      );
    },
    onSuccess: (response: Project) => {
      queryClient.invalidateQueries({
        queryKey: ['projects', props.project.id],
        exact: true,
      });
      addSnackbar(getDefaultSuccessSnackBar('Votre projet a été signé !'));
      props.onClose();
    },
    onError: (error: Error) => {
      let errorMessage: string = 'Une erreur est survenue.';
      if (error instanceof RestError && error.status === 400)
        errorMessage =
          (error.response as ApiResponse<any>).detail || errorMessage;

      addSnackbar(getDefaultErrorSnackBar(errorMessage));
      formikRef.current?.setFieldError('consent', errorMessage);
    },
  });

  return (
    <Dialog
      open={props.open}
      fullWidth
      fullScreen={!screenSizeUpSM}
      maxWidth="md"
      onClose={(event, reason) => {
        if (mutationProjectSignatureManual.isPending) {
          return;
        }
        if (reason && reason === 'backdropClick') return;
        props.onClose();
      }}
      TransitionComponent={TransitionSlideUP}
    >
      <DialogTitle>
        <Typography variant="h2">Signature manuelle</Typography>
        <IconButton
          aria-label="close"
          onClick={() => props.onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            ':hover': {
              backgroundColor: 'rgba(250, 250, 250, 0.04)',
            },
          }}
        >
          <CloseIcon color={theme.palette.text.primary} />
        </IconButton>
      </DialogTitle>

      <Formik
        innerRef={formikRef}
        initialValues={{
          commercialProposition: undefined,
          consent: false,
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          if (!values.commercialProposition) return;

          mutationProjectSignatureManual.mutate({
            commercialProposition: values.commercialProposition,
          });
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          submitForm,
        }) => {
          return (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Alert
                      icon={false}
                      variant="outlined"
                      severity="warning"
                      sx={{
                        border: 2,
                        borderColor: theme.palette.warning.main,
                        backgroundColor: alpha(theme.palette.warning.main, 0.1),
                      }}
                    >
                      <Typography variant="body2">
                        Vous vous apprêtez à ajouter le projet de souscription
                        signé.
                      </Typography>
                      <Typography sx={{ pt: 0.5 }}>
                        Une fois validé, ce projet ne pourra plus être modifié
                        et la mise en gestion se fera automatiquement.
                      </Typography>
                    </Alert>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        visibility: values.commercialProposition
                          ? 'hidden'
                          : 'visible',
                        height: values.commercialProposition ? '0px' : 'auto',
                      }}
                    >
                      <DropzoneDocument
                        ref={dropzoneRef}
                        label="ou glissez et déposer le projet de souscription signé ici."
                        acceptedTypes={[
                          ...DEFAULT_FILE_ACCEPT,
                          MimeType.APPLICATION_ZIP,
                        ]}
                        maxSize={DEFAULT_FILE_MAX_SIZE}
                        nbFiles={values.commercialProposition ? 1 : 0}
                        maxFiles={values.commercialProposition ? 2 : 1}
                        onDrop={file => {
                          setFieldTouched('commercialProposition');
                          setFieldValue('commercialProposition', file[0], true);
                        }}
                      />
                    </Box>

                    {values.commercialProposition &&
                      (() => {
                        const file = values.commercialProposition as File;
                        const extension: string | undefined = file.name
                          .split('.')
                          ?.pop();
                        const mimeType = extension
                          ? getMimeTypeFromExtension(extension)
                          : undefined;
                        const mimeTypeDetail = mimeType
                          ? mimeTypeDetails[mimeType]
                          : undefined;

                        return (
                          <>
                            <CardDocument
                              name={file?.name}
                              size={file?.size}
                              onDelete={() =>
                                setFieldValue(
                                  'commercialProposition',
                                  undefined,
                                )
                              }
                              onPreview={
                                !mimeTypeDetail?.previewEnabled
                                  ? undefined
                                  : () => {
                                      if (!values.commercialProposition) return;
                                      const fileURL = URL.createObjectURL(
                                        values.commercialProposition,
                                      );
                                      window.open(fileURL, '_blank');
                                      setTimeout(
                                        () => URL.revokeObjectURL(fileURL),
                                        5000,
                                      );
                                    }
                              }
                              onDownload={
                                mimeTypeDetail?.previewEnabled
                                  ? undefined
                                  : () => {
                                      if (!file) return;
                                      const fileURL = URL.createObjectURL(file);
                                      const link = document.createElement('a');
                                      link.href = fileURL;
                                      link.download = file.name;
                                      link.click();
                                      setTimeout(
                                        () => URL.revokeObjectURL(fileURL),
                                        5000,
                                      );
                                    }
                              }
                              onUpdate={() => handleOpenDropzone()}
                            />
                          </>
                        );
                      })()}
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions
                sx={{
                  px: 3,
                  pb: 2,
                  pt: 0,
                }}
              >
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox color="success" />}
                      checked={values.consent}
                      name="consent"
                      label={
                        <div style={{ marginTop: '9px' }}>
                          Je confirme que le document correspond au projet de
                          souscription signé.
                        </div>
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{
                        mr: 0,
                        alignItems: 'flex-start',
                      }}
                    />
                    {errors.consent && touched.consent && (
                      <FormHelperText error>{errors.consent}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item sm="auto" xs={12}>
                    <Button
                      fullWidth
                      variant="text"
                      color="default"
                      onClick={() => props.onClose()}
                      disabled={mutationProjectSignatureManual.isPending}
                      sx={{ px: 4 }}
                    >
                      Annuler
                    </Button>
                  </Grid>

                  <Grid item sm="auto" xs={12}>
                    <Button
                      fullWidth
                      onClick={() => submitForm()}
                      disabled={
                        errors?.commercialProposition !== undefined ||
                        Object.entries(touched).length === 0 ||
                        !touched?.commercialProposition ||
                        mutationProjectSignatureManual.isPending
                      }
                      sx={{ px: 4 }}
                    >
                      {mutationProjectSignatureManual.isPending ? (
                        <CircularProgress color="inherit" size={28} />
                      ) : (
                        'Valider'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

DialogSignatureUpload.displayName = 'DialogSignatureUpload';

export default DialogSignatureUpload;
