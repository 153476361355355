import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import { TrfDisclaimer } from 'models/referentiels/Tarification.models';

type TarificationDisclamerProps = {
  disclamers?: TrfDisclaimer;
};

export const TarificationDisclamers: FC<TarificationDisclamerProps> = ({
  disclamers,
}) =>
  disclamers?.tarificationValidity ? (
    <Typography variant="caption" component="div" color="text.primary">
      📍 {disclamers.tarificationValidity}
    </Typography>
  ) : null;
