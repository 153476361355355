import React from 'react';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { GiftIcon } from 'components/Icon/GiftIcon';
import { Promotion } from 'models/oav/Promotion.model';

export type PromotionCardProps = {
  promotion: Promotion;
};

export const PromotionCard: React.FC<PromotionCardProps> = ({
  promotion,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        p: 1,
        border: 1,
        borderColor: theme.palette.secondary.main,
        containerType: 'inline-size',
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Box
          sx={{
            '@container (max-width: 400px)': {
              display: 'none',
            },
          }}
        >
          <GiftIcon size="large" width="40" height="40" />
        </Box>

        <Stack direction="column" flexGrow={1}>
          <Typography variant="body2" fontWeight="bold">
            {promotion.label}
          </Typography>
          <Typography variant="caption">{promotion.description}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
