import { useMemo, useState } from 'react';

import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PriceNumber from 'content/formula/PriceNumber';
import CardSolutionItem from 'content/solution/CardSolutionItem';

import {
  enumTarificationPeriod,
  Solution,
  SolutionItem,
  TarificationPeriod,
} from 'models/oav/Solution.models';
import { Offer } from 'models/referentiels/Offer.model';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TransitionSlideUP } from 'utils/animations/transitions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useMutation } from '@tanstack/react-query';
import { solutionMutations } from 'api/oav/SolutionQuery.api';
import { useAppContext } from 'components/Context/AppContext';
import { getDefaultErrorSnackBar } from 'utils/snackbars/Snackbars';
import { Subscription } from 'models/oav/Subscription.models';
import { Project } from 'models/oav/Project.models';

export type DialogSolutionDeleteProps = {
  project: Project;
  subscription: Subscription;
  solution: Solution;
  open: boolean;
  onDelete: () => void;
  onClose: () => void;
};

const DialogSolutionDelete = (props: DialogSolutionDeleteProps) => {
  const { addSnackbar } = useAppContext();

  const mutationOptions = {
    onSuccess: () => {
      props.onDelete();
    },
    onError: () => {
      addSnackbar(getDefaultErrorSnackBar('Une erreur est survenue.'));
    },
  };

  const deleteMutation = solutionMutations.useDeleteSolution(mutationOptions);

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="sm"
      onClose={(event, reason) => {
        if (deleteMutation.isPending) {
          return;
        }
        if (reason && reason === 'backdropClick') return;
        props.onClose();
      }}
      TransitionComponent={TransitionSlideUP}
    >
      <DialogTitle>
        <Typography variant="h2">
          Supprimer la solution n°{props.solution.order}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Êtes-vous sûr de vouloir supprimer cette solution ?
            </Typography>
            <Typography variant="body2">
              Toute suppression est définitive.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          px: 3,
          pb: 2,
          pt: 0,
        }}
      >
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={12} sm="auto">
            <Button
              fullWidth
              variant="text"
              color="default"
              onClick={() => props.onClose()}
              disabled={deleteMutation.isPending}
            >
              Annuler
            </Button>
          </Grid>

          <Grid item xs={12} sm="auto">
            <Button
              fullWidth
              color="error"
              onClick={() =>
                deleteMutation.mutate({
                  id: props.solution.id,
                  projectId: props.project.id,
                  subscriptionId: props.subscription.id,
                })
              }
              disabled={deleteMutation.isPending}
              sx={{ px: 4 }}
            >
              Supprimer
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSolutionDelete;
