import { useMemo, useState } from 'react';

import { alpha, useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  enumTarificationPeriod,
  TarificationPeriod,
} from 'models/oav/Solution.models';
export type ChipPeriodProps = {
  period?: TarificationPeriod;
  disabled?: boolean;
  onChange?: (period: TarificationPeriod) => void;
};

const ChipPeriod = (props: ChipPeriodProps) => {
  const theme = useTheme();

  const enabled = !props.disabled && props.onChange;

  const [anchorPeriodChip, setAnchorPeriodChip] = useState<null | HTMLElement>(
    null,
  );

  const openPeriodMenu = (e: any) => {
    e.stopPropagation();
    if (!props.onChange) return;
    setAnchorPeriodChip(e.currentTarget);
  };

  const period = useMemo(() => {
    return (
      enumTarificationPeriod.find(
        _ => _.value === (props.period || TarificationPeriod.MONTHLY),
      ) || undefined
    );
  }, [props.period]);

  return (
    <>
      <Chip
        size="small"
        label={period?.label}
        deleteIcon={<ExpandMoreIcon />}
        onDelete={props.onChange && openPeriodMenu}
        onClick={props.onChange && openPeriodMenu}
      />

      {props.onChange && (
        <Popper
          open={anchorPeriodChip !== null}
          anchorEl={anchorPeriodChip}
          placement="bottom"
          sx={{
            zIndex: 10000,
          }}
        >
          <ClickAwayListener onClickAway={() => setAnchorPeriodChip(null)}>
            <Paper
              sx={{
                borderRadius: 2,
              }}
            >
              <List dense>
                {enumTarificationPeriod.map(_ => {
                  const selected = period?.value === _.value;
                  return (
                    <ListItemButton
                      key={_.value}
                      selected={selected}
                      onClick={e => {
                        e.stopPropagation();
                        props.onChange && props.onChange(_.value);
                        setAnchorPeriodChip(null);
                      }}
                    >
                      <Typography
                        variant="caption"
                        fontWeight={selected ? 700 : 500}
                      >
                        {_.label}
                      </Typography>
                    </ListItemButton>
                  );
                })}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
};

export default ChipPeriod;
