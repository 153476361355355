import { useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ChevronBottomIcon } from 'components/Icon/ChevronBottomIcon';
import { OfferDescription } from 'content/offer/OfferDescription';
import { CardFormulaList } from 'content/formula/CardFormulaList';
import WarrantySectionAccordion from 'content/warranty/WarrantySectionAccordion';

import { Formula } from 'models/referentiels/Formula.model';
import { Offer, OfferType } from 'models/referentiels/Offer.model';
import { Tarification } from 'models/referentiels/Tarification.models';

import { offerQueries } from 'api/referentiels/OfferQuery.api';
import { warrantyQueries } from 'api/referentiels/WarrantyQuery.api';
import useOnScreen from 'utils/hooks/useOnScreen';

export type CardOfferDetailProps = {
  offer: Offer;
  tarification?: Tarification;
  error?: boolean;
  helperText?: string;
  enableWarranties?: boolean;
  recommendedFormulaCode?: string;
  baseFormulas?: Formula[];
  selectedFormula?: string;
  selectableFormulas?: string[];
  onFormulaSelected?: (formula: Formula, selected: boolean) => void;
};

const CardOfferDetail = (props: CardOfferDetailProps) => {
  const theme = useTheme();

  const screenSizeUpLG = useMediaQuery(theme.breakpoints.up('lg'));
  const refHeader = useRef<HTMLDivElement | null>(null);
  const isHeaderVisible = useOnScreen(refHeader, { threshold: 0 });

  const [showWarranties, setShowWarranties] = useState<boolean>(false);
  const warrantiesQuery = useQuery({
    ...offerQueries.getByCodeWarranties(props.offer.code),
    enabled: showWarranties,
  });
  const acronymsMapQuery = useQuery({
    ...warrantyQueries.getAcronymMap(),
    enabled: showWarranties,
  });

  return (
    <Card
      color="darken"
      sx={{
        overflow: 'visible',
        p: 2,
        width: '100%',
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          position: 'relative',
          overflow: 'visible',
        }}
      >
        <Grid item xs={12} ref={refHeader}>
          <OfferDescription offer={props.offer} />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            position: showWarranties ? 'sticky' : 'relative',
            top: 0,
            zIndex: 1000,
            mt: 1,
          }}
        >
          <CardFormulaList
            {...props}
            baseFormulas={showWarranties ? undefined : props.baseFormulas}
            showWarranties={showWarranties}
            labelOnLeftColumn={screenSizeUpLG}
            cardFormulaProps={{
              sx: {
                boxShadow: showWarranties ? theme.shadows[3] : 'none',
              },
            }}
          />
        </Grid>

        {props.helperText && (
          <Grid item xs={12}>
            <FormHelperText error={props.error === true}>
              {props.helperText}
            </FormHelperText>
          </Grid>
        )}

        {props.enableWarranties && (
          <Grid item xs={12}>
            <Collapse in={showWarranties}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight="bold">Garanties</Typography>
                  {props.offer.type === OfferType.REINFORCEMENT && (
                    <Typography variant="caption">
                      Les garanties de {props.offer.label} s'ajoutent aux
                      garanties de l'offre de base.
                    </Typography>
                  )}
                </Grid>

                {warrantiesQuery.isLoading && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      minHeight: '200px',
                    }}
                  >
                    <Skeleton
                      height="100%"
                      sx={{
                        transform: 'none',
                      }}
                    />
                  </Grid>
                )}

                {warrantiesQuery.isError && (
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        p: 2,
                      }}
                    >
                      <Stack spacing={1} alignItems="center">
                        <Typography>❌ Une erreur est survenue</Typography>
                        <Typography variant="caption">
                          Merci de réessayer ultérieurement.
                        </Typography>
                      </Stack>
                    </Card>
                  </Grid>
                )}

                {warrantiesQuery.data?.length === 0 ? (
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        p: 2,
                      }}
                    >
                      <Stack spacing={1} alignItems="center">
                        <Typography>📭 Aucunes garanties</Typography>
                      </Stack>
                    </Card>
                  </Grid>
                ) : (
                  warrantiesQuery.data?.map((section, idx) => {
                    return (
                      <Grid item xs={12} key={`warranties-section-${idx}`}>
                        <WarrantySectionAccordion
                          open={warrantiesQuery.data.length === 1}
                          labelOnLeftColumn={screenSizeUpLG}
                          section={section}
                          formulas={props.offer.formulas}
                          formulasSelected={
                            props.selectedFormula
                              ? [props.selectedFormula]
                              : undefined
                          }
                          acronymsMap={acronymsMapQuery.data}
                        />
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </Collapse>

            {(!warrantiesQuery.data || warrantiesQuery.data.length > 0) && (
              <Stack
                direction="row"
                justifyContent="center"
                spacing={2}
                sx={{
                  width: '100%',
                  display: {
                    md: 'inline-flex',
                    xs: 'none',
                  },
                  position: showWarranties ? 'sticky' : 'relative',
                  pt: showWarranties ? 2 : 0,
                  bottom: showWarranties ? 16 : 0,
                  textAlign: 'center',
                }}
              >
                <Button
                  sx={{
                    py: 0.5,
                  }}
                  color="default"
                  onClick={() => {
                    setShowWarranties(!showWarranties);
                    if (refHeader.current) {
                      refHeader.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    }
                  }}
                >
                  {!showWarranties
                    ? 'Voir les garanties'
                    : 'Masquer les garanties'}
                </Button>

                {showWarranties && !isHeaderVisible && (
                  <Tooltip title="Remonter" placement="top">
                    <Button
                      color="default"
                      onClick={() => {
                        if (refHeader.current) {
                          refHeader.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                          });
                        }
                      }}
                      sx={{
                        minWidth: 0,
                        p: 1,
                      }}
                    >
                      <ChevronBottomIcon
                        size="small"
                        style={{ transform: 'rotate(180deg)' }}
                      />
                    </Button>
                  </Tooltip>
                )}
              </Stack>
            )}
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default CardOfferDetail;
