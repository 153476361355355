import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';
import { Beneficiary } from 'models/oav/Beneficiary.models';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { regimeQueries } from 'api/referentiels/RegimeQuery.api';

export type CardBeneficiaryLinkProps = {
  beneficiary: Beneficiary;
  to?: string;
  disabled?: boolean;
};

const CardBeneficiaryLink = (props: CardBeneficiaryLinkProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // TODO : Add Link / onClick

  const regimesQuery = useQuery(regimeQueries.getAll());
  const regime = useMemo(() => {
    if (!regimesQuery.data) return undefined;
    return regimesQuery.data.find(
      r => r.code === props.beneficiary.person.regimeCode,
    );
  }, [regimesQuery.data, props.beneficiary.person.regimeCode]);

  return (
    <Card
      sx={{
        containerType: 'inline-size',
        borderRadius: 2,
        px: 1,
        py: 0,
        background: 'none',
        cursor: !props.disabled && props.to ? 'pointer' : 'default',
        ':hover': {
          background:
            !props.disabled && props.to
              ? theme.palette.primary.main + '20'
              : 'none',
        },
      }}
      onClick={() => !props.disabled && props.to && navigate(props.to)}
    >
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          {/* Firstname / Lastname */}
          <Typography
            component="div"
            variant="caption"
            sx={{
              color: theme.palette.text.primary,
            }}
          >
            {props.beneficiary.person.firstname}{' '}
            {props.beneficiary.person.lastname}
          </Typography>

          {/* BirthDate / Regime */}
          <Typography variant="caption">
            <Stack direction="row" spacing={1}>
              {props.beneficiary.person.birthdate && (
                <div>
                  <pre style={{ display: 'inline' }}>
                    {dayjs(props.beneficiary.person.birthdate).isValid()
                      ? dayjs(props.beneficiary.person.birthdate).format(
                          'DD/MM/YYYY',
                        )
                      : 'Date non valide'}
                  </pre>
                </div>
              )}

              {regime && (
                <Box
                  sx={{
                    '@container (max-width: 200px)': {
                      display: 'none',
                    },
                  }}
                >
                  {regime.label}
                </Box>
              )}
            </Stack>
          </Typography>
        </Box>

        {/* End component */}
        <></>
      </Stack>
    </Card>
  );
};

export default CardBeneficiaryLink;
