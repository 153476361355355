import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CardSolutionNew from 'content/solution/CardSolutionNew';
import CardSolution from 'content/solution/CardSolution';
import DialogSolutionDelete from 'content/solution/DialogSolutionDelete';
import CardError from 'components/Card/CardError';

import { subscriptionQueries } from 'api/oav/SubscriptionQuery.api';
import { Subscription } from 'models/oav/Subscription.models';
import { Project } from 'models/oav/Project.models';
import { Solution, TarificationPeriod } from 'models/oav/Solution.models';
import { Offer } from 'models/referentiels/Offer.model';

export type GridSubscriptionSolutionsProps = {
  project: Project;
  subscription: Subscription;
  offer: Offer;
  selectedSolutionId?: string;
  onUpdate?: (subscription: Subscription) => void;
  onSelectSolution?: (solution: Solution) => void;
  onCreateSolution?: (order: number) => void;
  onDeleteSolution?: (solution: Solution) => void;
  onClickSolution?: (solution: Solution) => void;
};

const GridSubscriptionSolutions = (props: GridSubscriptionSolutionsProps) => {
  const theme = useTheme();

  const [dialogSolutionDelete, setDialogSolutionDelete] = useState<Solution>();

  const [period, setPeriod] = useState<TarificationPeriod>(
    TarificationPeriod.MONTHLY,
  );

  const solutionsQuery = useQuery({
    ...subscriptionQueries.getByIdSolutions(
      props.project.id,
      props.subscription.id,
    ),
  });

  return (
    <Grid container spacing={2}>
      {dialogSolutionDelete && (
        <DialogSolutionDelete
          open={dialogSolutionDelete !== undefined}
          project={props.project}
          subscription={props.subscription}
          solution={dialogSolutionDelete}
          onDelete={() => {
            setDialogSolutionDelete(undefined);
          }}
          onClose={() => setDialogSolutionDelete(undefined)}
        />
      )}

      {solutionsQuery.isLoading &&
        Array.from({ length: 3 }).map((_, index) => (
          <Grid
            key={index}
            item
            md={4}
            xs={12}
            sx={{
              minHeight: '250px',
            }}
          >
            <Skeleton
              height="100%"
              sx={{
                transform: 'none',
              }}
            />
          </Grid>
        ))}

      {solutionsQuery.isError && (
        <Grid
          item
          xs={12}
          sx={{
            minHeight: '200px',
          }}
        >
          <CardError />
        </Grid>
      )}

      {solutionsQuery.data &&
        Array.from({ length: 3 })
          .fill(null)
          .map(
            (_, idx) =>
              solutionsQuery.data.find(s => s.order === idx + 1) || null,
          )
          .map((_, idx) => {
            const selected = _ !== null && props.selectedSolutionId === _.id;

            return (
              <Grid
                key={idx}
                item
                md={4}
                xs={12}
                sx={{
                  minHeight: '250px',
                }}
              >
                {_ === null ? (
                  <>
                    {props.onCreateSolution ? (
                      <CardSolutionNew
                        onClick={() => props.onCreateSolution?.(idx + 1)}
                      />
                    ) : (
                      <Card
                        sx={{
                          background: theme.palette.background.default,
                          height: '100%',
                          p: 2,
                        }}
                      >
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                          sx={{
                            height: '100%',
                          }}
                        >
                          <Typography>Solution n°{idx + 1}</Typography>
                          <Typography variant="caption">
                            Solution non renseignée
                          </Typography>
                        </Stack>
                      </Card>
                    )}
                  </>
                ) : (
                  <CardSolution
                    solution={_}
                    offer={props.offer}
                    period={period}
                    onPeriodChange={setPeriod}
                    selected={props.selectedSolutionId ? selected : undefined}
                    onDelete={
                      props.onDeleteSolution
                        ? () => setDialogSolutionDelete(_)
                        : undefined
                    }
                    onSelect={
                      props.onSelectSolution
                        ? () => props.onSelectSolution?.(_)
                        : undefined
                    }
                    onClick={
                      props.onClickSolution
                        ? () => props.onClickSolution?.(_)
                        : undefined
                    }
                  />
                )}
              </Grid>
            );
          })}
    </Grid>
  );
};

export default GridSubscriptionSolutions;
