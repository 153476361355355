import * as Yup from 'yup';

export const minBicLength = 8;
export const maxBicLength = 11;
export const ibanMaxLength = 27;
export const accountHolderMaxChar = 255;
export const accountHolderMinChar = 5;

export const yupAccountHolder = Yup.string()
  .trim()
  .min(
    accountHolderMinChar,
    `Titulaire du compte non valide (minimum ${accountHolderMinChar} caractères requis)`,
  )
  .max(
    accountHolderMaxChar,
    `Titulaire du compte non valide (maximum ${accountHolderMaxChar} caractères requis)`,
  );

export const yupIban = Yup.string()
  .trim()
  .matches(/^[A-Z0-9]*$/, 'IBAN non valide (caractères alphanumérique requis)')
  .max(
    ibanMaxLength,
    `IBAN non valide (maximum ${ibanMaxLength} caractères requis)`,
  );

export const yupBic = Yup.string()
  .trim()
  .matches(/^[A-Z0-9]*$/, 'BIC non valide (caractères alphanumérique requis)')
  .min(minBicLength, `BIC non valide (${minBicLength} caractères requis)`)
  .max(maxBicLength, `BIC non valide (${maxBicLength} caractères requis)`);
