import { TextFieldProps } from '@mui/material/TextField';
import FormattedTextField from './FormattedTextField';
import { bicRegex } from 'utils/fields/fields';
import { useMemo } from 'react';

const toUpperCase = (value: string) => value.toUpperCase();
const removeRegex = /[^A-Z\d]/g;

export const BICTextField = (props: TextFieldProps) => {
  const inputProps = useMemo(() => {
    return {
      ...props.inputProps,
      minLength: 14,
      maxLength: 14,
    };
  }, [props.inputProps]);

  return (
    <FormattedTextField
      {...props}
      groupsRegex={bicRegex}
      removeRegex={removeRegex}
      separator=" "
      transform={toUpperCase}
      inputProps={inputProps}
    />
  );
};
