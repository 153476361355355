import { Icon } from '.';
import { IconProps } from './settings';

export const AddCircleIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z"
            stroke="currentColor"
          />
          <path
            d="M13.7184 24.0666C13.7031 24.5623 14.1381 24.9781 14.6901 24.9952C15.2422 25.0124 15.7021 24.6244 15.7175 24.1286L15.9685 16.0305L24.0666 16.2817C24.5624 16.297 24.9782 15.862 24.9953 15.3099C25.0124 14.7579 24.6244 14.2979 24.1286 14.2826L16.0306 14.0314L16.2817 5.93348C16.2971 5.43774 15.862 5.02195 15.31 5.00481C14.758 4.98774 14.298 5.37575 14.2827 5.87149L14.0316 13.9695L5.93352 13.7183C5.43777 13.703 5.022 14.138 5.00488 14.69C4.98776 15.242 5.37578 15.702 5.87154 15.7174L13.9696 15.9685L13.7184 24.0666Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </Icon>
  );
};
