import { Icon } from './index';
import { IconProps } from './settings';

export const GiftIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9443 29H28.5554V53H17.9443C16.2875 53 14.9443 51.6569 14.9443 50V29Z"
          fill="currentColor"
        />
        <path
          d="M46.0554 50C46.0554 51.6569 44.7123 53 43.0554 53H32.4443V29H46.0554V50Z"
          fill="currentColor"
        />
        <path
          d="M46 19H15C13.8954 19 13 19.8954 13 21V24C13 25.1046 13.8954 26 15 26H46C47.1046 26 48 25.1046 48 24V21C48 19.8954 47.1046 19 46 19Z"
          fill="currentColor"
        />
        <path
          d="M19.3921 14C19.3921 11.5147 21.4069 9.5 23.8921 9.5H24.3537C27.0939 9.5 29.3152 11.7214 29.3152 14.4615V18.5H23.8921C21.4069 18.5 19.3921 16.4853 19.3921 14Z"
          stroke="currentColor"
          stroke-width="3"
        />
        <path
          d="M41.6848 14C41.6848 11.5147 39.6701 9.5 37.1848 9.5H36.7232C33.9831 9.5 31.7617 11.7214 31.7617 14.4615V18.5H37.1848C39.67 18.5 41.6848 16.4853 41.6848 14Z"
          stroke="currentColor"
          stroke-width="3"
        />
        <path
          d="M3.5 30L4.1752 31.8248L6 32.5L4.1752 33.1752L3.5 35L2.82477 33.1752L1 32.5L2.82477 31.8248L3.5 30Z"
          fill="currentColor"
        />
        <path
          d="M9.1121 5L10.2228 8.0015L13.2243 9.1121L10.2228 10.2228L9.1121 13.2243L8.0015 10.2228L5 9.1121L8.0015 8.0015L9.1121 5Z"
          fill="currentColor"
        />
        <path
          d="M54.1121 49L55.2228 52.0015L58.2243 53.1121L55.2228 54.2228L54.1121 57.2243L53.0015 54.2228L50 53.1121L53.0015 52.0015L54.1121 49Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
};
