import { useSegments } from 'queries';
import Stack from '@mui/material/Stack';
import SelectFilter from 'components/Select/SelectFilter';
import { Segment } from 'models/referentiels/Segment';
import FilterChip from 'components/Chip/FilterChip';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { Form, useFormikContext } from 'formik';
import DatePicker from 'components/DatePicker/DatePicker';
import * as Yup from 'yup';
import { Dayjs } from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import { offerQueries } from 'api/referentiels/OfferQuery.api';
import { Offer, OfferType } from 'models/referentiels/Offer.model';
import {
  getProjectPhaseAsEntries,
  ProjectPhase,
  ProjectPhaseData,
} from 'models/oav/ProjectPhase.models';
import Chip from '@mui/material/Chip';
import React from 'react';
import {
  getProjectStatusAsEntries,
  ProjectStatus,
  ProjectStatusData,
} from 'models/oav/ProjectStatus.models';

export const validationSchema = Yup.object().shape({
  fromDate: Yup.date().typeError('Date non valide').nullable(),
  toDate: Yup.date().typeError('Date non valide').nullable(),
});

export interface ProjectFormData {
  search: string;
  segments: Segment[];
  statuses: [ProjectStatus, ProjectStatusData][];
  offers: Offer[];
  phases: [ProjectPhase, ProjectPhaseData][];
  fromDate: Dayjs | null;
  toDate: Dayjs | null;
}

export interface ProjectFilterProps {
  nbProject?: number;
}

export const ProjectFilter: React.FC<ProjectFilterProps> = ({ nbProject }) => {
  const theme = useTheme();

  const { data: segmentOptions } = useSegments();
  const { data: offerOptions } = useQuery(
    offerQueries.getAll({
      inTypes: [OfferType.BASE, OfferType.OPTION],
    }),
  );

  const phaseOptions = getProjectPhaseAsEntries();
  const statusOptions = getProjectStatusAsEntries();

  const { values, errors, touched, handleBlur, setFieldValue, resetForm } =
    useFormikContext<ProjectFormData>();

  const showFromDatePill = values.fromDate && values.fromDate.isValid();
  const showToDatePill = values.toDate && values.toDate.isValid();

  const showPills =
    values.offers.length > 0 ||
    values.segments.length > 0 ||
    values.phases.length > 0 ||
    values.statuses.length > 0 ||
    showFromDatePill ||
    showToDatePill;

  return (
    <Stack>
      <Form>
        <Stack direction="row" gap={2}>
          <DatePicker
            sx={{
              fieldset: {
                borderWidth: 2,
                borderColor: theme.palette.text.primary,
              },
            }}
            variant="outlined"
            label="Date de début"
            format="DD/MM/YYYY"
            openTo="year"
            name="fromDate"
            maxDate={values.toDate}
            value={values.fromDate ? values.fromDate : 'clear'}
            onChange={v => setFieldValue('fromDate', v)}
            slotProps={{
              textField: {
                fullWidth: false,
                onBlur: handleBlur,
                error: !!touched.fromDate && !!errors.fromDate,
                helperText: !!touched.fromDate && <>{errors.fromDate}</>,
              },
            }}
          />
          <DatePicker
            sx={{
              fieldset: {
                borderWidth: 2,
                borderColor: theme.palette.text.primary,
              },
            }}
            variant="outlined"
            label="Date de fin"
            format="DD/MM/YYYY"
            openTo="year"
            name="toDate"
            minDate={values.fromDate}
            value={values.toDate ? values.toDate : 'clear'}
            onChange={v => setFieldValue('toDate', v)}
            slotProps={{
              textField: {
                fullWidth: false,
                onBlur: handleBlur,
                error: !!touched.toDate && !!errors.toDate,
                helperText: !!touched.toDate && <>{errors.toDate}</>,
              },
            }}
          />
          <SelectFilter
            disabled={false}
            onChange={(_, value) => setFieldValue('segments', value)}
            multiple={true}
            showCount={true}
            options={segmentOptions}
            value={values.segments}
            getOptionKey={option => option.code}
            renderElement={option => option.label}
          >
            Segment
          </SelectFilter>
          <SelectFilter
            disabled={false}
            onChange={(_, value) => setFieldValue('offers', value)}
            multiple={true}
            showCount={true}
            options={offerOptions}
            value={values.offers}
            getOptionKey={option => option.code}
            renderElement={option => option.label}
          >
            Offre
          </SelectFilter>
          <SelectFilter
            disabled={false}
            onChange={(_, value) => setFieldValue('phases', value)}
            multiple={true}
            showCount={true}
            options={phaseOptions}
            value={values.phases}
            getOptionKey={([key]) => key}
            renderElement={([, data]) => data.label}
            isOptionEqualToValue={([optionKey], [valueKey]) =>
              optionKey == valueKey
            }
          >
            Phase
          </SelectFilter>
          <SelectFilter
            disabled={false}
            onChange={(_, value) => setFieldValue('statuses', value)}
            multiple={true}
            showCount={true}
            options={statusOptions}
            value={values.statuses}
            getOptionKey={([key]) => key}
            renderElement={([, data]) => data.label}
            isOptionEqualToValue={([optionKey], [valueKey]) =>
              optionKey == valueKey
            }
          >
            État
          </SelectFilter>
          <Stack
            flexDirection="row"
            alignItems="center"
            sx={{ marginLeft: 'auto' }}
          >
            Total de projets :&nbsp;
            <Chip
              label={nbProject ?? 0}
              color="default"
              sx={{
                backgroundColor: theme.palette.background.paper,
                fontWeight: 'bold',
              }}
              variant="filled"
              size="small"
            />
          </Stack>
        </Stack>
      </Form>
      {showPills && (
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          useFlexGap
          spacing={2}
          sx={{ mt: 2 }}
        >
          {showFromDatePill && (
            <FilterChip
              key={'dateStart'}
              item={{
                label: 'Après le ' + values.fromDate?.format('DD/MM/YYYY'),
              }}
              theme={theme}
              onDelete={() => setFieldValue('fromDate', null)}
            />
          )}

          {showToDatePill && (
            <FilterChip
              key={'dateEnd'}
              item={{
                label: 'Avant le ' + values.toDate?.format('DD/MM/YYYY'),
              }}
              theme={theme}
              onDelete={() => setFieldValue('toDate', null)}
            />
          )}

          {values.segments.map(item => (
            <FilterChip
              key={item.code}
              item={item}
              theme={theme}
              onDelete={() =>
                setFieldValue(
                  'segments',
                  values.segments.filter(element => element.code !== item.code),
                )
              }
            />
          ))}

          {values.offers.map(item => (
            <FilterChip
              key={item.code}
              item={item}
              theme={theme}
              onDelete={() =>
                setFieldValue(
                  'offers',
                  values.offers.filter(element => element.code !== item.code),
                )
              }
            />
          ))}
          {values.phases.map(([itemKey, itemData]) => (
            <FilterChip
              key={itemKey}
              item={itemData}
              theme={theme}
              onDelete={() =>
                setFieldValue(
                  'phases',
                  values.phases.filter(([key]) => itemKey !== key),
                )
              }
            />
          ))}
          {values.statuses.map(([itemKey, itemData]) => (
            <FilterChip
              key={itemKey}
              item={itemData}
              theme={theme}
              onDelete={() =>
                setFieldValue(
                  'statuses',
                  values.statuses.filter(([key]) => itemKey !== key),
                )
              }
            />
          ))}
          <Link onClick={() => resetForm()}>Tout effacer</Link>
        </Stack>
      )}
    </Stack>
  );
};
