export interface Promotion {
  label?: string;
  description?: string;
}

export const firstMonthPromotion: Promotion = {
  label: '1 MOIS OFFERT',
  description:
    'Vous bénéficiez d’un mois de cotisation offert, le 12ème mois de votre adhésion.',
};
