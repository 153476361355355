import { alpha, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PriceNumber from 'content/formula/PriceNumber';

import { Solution, TarificationPeriod } from 'models/oav/Solution.models';

export type CardSolutionLinkProps = {
  solution: Solution;
  selected?: boolean;
  disabled?: boolean;
  period?: TarificationPeriod;
  onClick?: () => void;
};

const CardSolutionLink = (props: CardSolutionLinkProps) => {
  const theme = useTheme();
  const enabled = !props.disabled && props.onClick;

  return (
    <Card
      onClick={() => !props.disabled && props.onClick?.()}
      sx={{
        borderRadius: 2,
        px: 1,
        py: 0.25,
        background: props.selected
          ? alpha(theme.palette.primary.main, 0.1)
          : 'none',
        cursor: enabled ? 'pointer' : 'default',
        ':hover': {
          background:
            props.selected || enabled
              ? alpha(theme.palette.primary.main, 0.1)
              : 'none',
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="caption"
          color={props.selected ? 'primary' : ''}
          fontWeight={props.selected ? 700 : 500}
        >
          Solution n°{props.solution.order}
        </Typography>
        <Typography variant="caption">
          <PriceNumber
            price={
              props.period === TarificationPeriod.ANNUALLY
                ? props.solution.pricing?.perYear
                : props.solution.pricing?.perMonth
            }
          />
        </Typography>
      </Stack>
    </Card>
  );
};

export default CardSolutionLink;
