//@ts-ignore
import AnimatedNumber from 'animated-number-react';

export type PriceNumberProps = {
  price?: number;
  currency?: string;
};

const PriceNumber = (props: PriceNumberProps) => {
  const currencySymbol: Record<string, string> = {
    EUR: '€',
    USD: '$',
    GBP: '£',
  };

  return (
    <>
      <pre style={{ display: 'inline-flex' }}>
        {props.price ? (
          <AnimatedNumber
            value={props.price}
            duration={200}
            easing="linear"
            formatValue={(value: number) =>
              value.toLocaleString('fr-FR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }
          />
        ) : (
          '-'
        )}
      </pre>{' '}
      {props.currency ? currencySymbol[props.currency] : '€'}
    </>
  );
};

PriceNumber.displayName = 'PriceNumber';

export default PriceNumber;
